import React from 'react';
import {
  Create, SimpleForm, AutocompleteInput, ReferenceInput, SelectInput, NumberInput, TextInput,
} from 'react-admin';

const CreateQuestion = (props) => (
  <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    resource="Questions"
    transform={(data) => ({ ...data, t: 'Questions', version: 1 })}
  >
    <SimpleForm redirect="list">
      <ReferenceInput label="Category" source="categoryID" resource="categoryy" reference="categories">
        <AutocompleteInput
          optionText="name"
        />
      </ReferenceInput>
      <NumberInput source="set" min={1} max={100} step={1} />
      <NumberInput label="Question" source="number" min={1} max={100} step={1} />
      <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
      <SelectInput
        source="type"
        choices={[
          { id: 'RATING', name: 'Rating' },
          { id: 'TEXT', name: 'Text' },
          { id: 'UPDOWN', name: 'Updown' },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default CreateQuestion;
