/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import {
  Create, SimpleForm, TextInput, ImageInput,
  ImageField, useMutation, useRedirect,
} from 'react-admin';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

const CreateCareerCentre = (props) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Image
      if (values?.image) {
        const { title: imageTitle } = values.image;
        const uniqueImageTitle = `careerCentres/${uuidv4()}-${imageTitle}`;
        await Storage.put(uniqueImageTitle, values.image.rawFile, {
          level: 'public',
          contentType: values.image.rawFile.type,
        });
        values.image = uniqueImageTitle;
      }

      values.t = 'CareerCentres';
      values.version = 0;

      try {
        await mutate({
          type: 'create',
          resource: 'CareerCentres',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/careerCentres');
      } catch (error) {
        if (error?.body?.errors) {
          return error?.body?.errors;
        }
      }
    },
    [mutate],
  );

  return (
    <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      resource="careerCentres"
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
        <ImageInput source="image" label="Career Centre Image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="address.addressLine1" label="Address Line 1" />
        <TextInput source="address.addressLine2" label="Address Line 2" />
        <TextInput source="address.suburb" label="Suburb" />
        <TextInput source="address.city" label="City" />
        <TextInput source="address.province" label="Province" />
        <TextInput source="contact.name" label="Contact Name" />
        <TextInput source="contact.phoneNumber" label="Contact Phone Number 1" />
        <TextInput source="contact.phoneNumber2" label="Contact Phone Number 2" />
        <TextInput source="contact.emailAddress" label="Contact Email Address" />
        <TextInput source="contact.websiteURL" label="Website URL" />
      </SimpleForm>
    </Create>
  );
};

export default CreateCareerCentre;
