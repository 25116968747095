import React from 'react';
import {
  TopToolbar, CreateButton, RefreshButton,
  EditButton, List, Datagrid, TextField, Loading,
} from 'react-admin';
import { PropTypes } from 'prop-types';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { useAmplifyMigration } from '../Utils';
import { minimalListSponsors } from '../graphql/extended/queries';
import { updateSponsor } from '../graphql/mutations';
import { RedirectField } from '../Fields';

const SponsorActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

SponsorActions.propTypes = {
  basePath: PropTypes.string.isRequired,
};

const migrations = {
  0: (items) => items
    // .filter((i) => i?.version < 0)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateSponsor, { input: { id: item.id, t: 'Sponsors', version: 0 } }))
        .then(() => {
        });
    }, Promise.resolve()),
  1: (items) => items
    .filter((i) => i?.version < 1)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateSponsor, { input: { id: item.id, content: item.content.replace(/(<([^>]+)>)/gi, ''), version: 1 } }))
        .then(() => {
        });
    }, Promise.resolve()),
  2: (items) => items
    .filter((i) => i?.version < 2)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      if (!item?.logo || item?.logo.includes('sponsors/logos/')) { // Logo in correct location, increase version and continue
        return API.graphql(graphqlOperation(updateSponsor, { input: { id: item.id, version: 2 } }))
          .then(() => {
          });
      } // Update path and move file
      return API.graphql(graphqlOperation(updateSponsor, { input: { id: item.id, logo: `sponsors/logos/${item.logo}`, version: 2 } }))
        .then(async () => {
          await Storage.copy({ key: item.logo }, { key: `sponsors/logos/${item.logo}` });
        });
    }, Promise.resolve()),
};

const SponsorList = (props) => {
  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListSponsors, 'listSponsors', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      actions={<SponsorActions />}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="license_count" label="License Count" />
        <TextField source="contact.emailAddress" label="Contact" />
        <TextField source="contact.websiteURL" label="Website" />
        <RedirectField path="/sponsorCodes" query="listSponsorCodesBySponsorIdByUsedByCode" queryField="sponsorID" label="Codes" />
        <RedirectField path="/sponsorStudentBridgesBySponsorId" query="listSponsorStudentBridgesBySponsorId" queryField="sponsorID" label="Students" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default SponsorList;
