import React from 'react';
import {
  Edit, SimpleForm, TextInput, SelectInput,
} from 'react-admin';

const EditGrade = (props) => (
  <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    title="Edit grade"
    transform={(data) => {
      /**
       * Remove keys for relations from the object because
       * react-admin devs are not good at graphql
       * and seem to build up the schema for this
       * object based on the get query which includes relations.
       */
      // eslint-disable-next-line no-param-reassign
      delete data.students;
      return data;
    }}
  >
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput
        source="status"
        choices={[
          { id: 'PENDING_VALIDATION', name: 'Pending Validation' },
          { id: 'VALIDATED', name: 'Validated' },
          { id: 'ARCHIVED', name: 'Archived' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default EditGrade;
