/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { stripUUID } from '../Utils';

const useStyles = makeStyles({
  card: {
    minWidth: 225,
    maxWidth: 225,
    overflow: 'scroll',
    maxHeight: '400px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 999,
  },
  title: {
    fontSize: 14,
  },
});

const UploadProgress = (props) => {
  const { fileProgress } = props;
  const styles = useStyles();

  const uploadedFileAmount = Object.keys(fileProgress).length;

  return uploadedFileAmount > 0 ? ( // Probably filter out status 1?
    <Card className={styles.card}>
      <CardContent>
        <Typography className={styles.title} gutterBottom>Uploads</Typography>
        { Object.keys(fileProgress).map((file) => (
          <>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">
                {stripUUID(fileProgress[file].id)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <LinearProgress value={fileProgress[file].progress} variant="determinate" />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                  {`${fileProgress[file].progress}%`}
                </Typography>
              </Box>
            </Box>
          </>
        )) }
      </CardContent>
    </Card>
  ) : null;
};

const mapStateToProps = (state) => ({
  fileProgress: state.fileProgress,
});

UploadProgress.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fileProgress: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(UploadProgress);
