/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Edit, SimpleForm, TextInput, ImageInput, ImageField,
  useMutation, useRedirect, FormDataConsumer, Labeled,
} from 'react-admin';
import { Storage, API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { getCareerCentre } from '../graphql/queries';

const EditCareerCentre = (props) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const [currentImageSrc, setCurrentImageSrc] = useState('');

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Image
      if (values?.image?.src) {
        const { title: imageTitle } = values.image;
        const uniqueImageTitle = `careerCentres/${uuidv4()}-${imageTitle}`;
        await Storage.put(uniqueImageTitle, values.image.rawFile, {
          level: 'public',
          contentType: values.image.rawFile.type,
        });
        values.image = uniqueImageTitle;
      }

      try {
        await mutate({
          type: 'update',
          resource: 'CareerCentres',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/careerCentres');
      } catch (error) {
        if (error?.body?.errors) {
          return error?.body?.errors;
        }
      }
    },
    [mutate],
  );

  useEffect(async () => {
    const { id } = props;
    const category = await API.graphql({ query: getCareerCentre, variables: { id } });
    // eslint-disable-next-line no-prototype-builtins
    const src = category.data.getCareerCentre.hasOwnProperty('image')
      ? await Storage.get(category.data.getCareerCentre.image)
      : '';
    setCurrentImageSrc(src);
  }, []);

  return (
    <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title="Edit Career Centre"
      transform={(data) => {
        /**
       * Remove keys for relations from the object because
       * react-admin devs are not good at graphql
       * and seem to build up the schema for this
       * object based on the get query which includes relations.
       */
        // eslint-disable-next-line no-param-reassign
        delete data.students;
        return data;
      }}
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
        <ImageInput source="image" label="Career Centre Image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ data }) => {
            if (!data?.image?.src) {
              return (
                <div>
                  <Labeled label="Current image">
                    <img alt="Current" src={currentImageSrc} style={{ width: '30%' }} />
                  </Labeled>
                </div>
              );
            } return null;
          }}
        </FormDataConsumer>
        <TextInput source="address.addressLine1" label="Address Line 1" />
        <TextInput source="address.addressLine2" label="Address Line 2" />
        <TextInput source="address.suburb" label="Suburb" />
        <TextInput source="address.city" label="City" />
        <TextInput source="address.province" label="Province" />
        <TextInput source="contact.name" label="Contact Name" />
        <TextInput source="contact.phoneNumber" label="Contact Phone Number 1" />
        <TextInput source="contact.phoneNumber2" label="Contact Phone Number 2" />
        <TextInput source="contact.emailAddress" label="Contact Email Address" />
        <TextInput source="contact.websiteURL" label="Website URL" />
      </SimpleForm>
    </Edit>
  );
};

EditCareerCentre.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditCareerCentre;
