/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

const RedirectField = ({
  // eslint-disable-next-line react/prop-types
  record = {}, path, query, queryField, label,
}) => (
  <Button
    component={Link}
    to={{
      pathname: `${path}`,
      search: `filter=${JSON.stringify({ [query]: { [queryField]: record.id } })}`,
    }}
    label={`${label}`}
  />
);

RedirectField.propTypes = {
  record: PropTypes.object,
  path: PropTypes.string,
  query: PropTypes.string,
  queryField: PropTypes.string,
  label: PropTypes.string,
};

export default RedirectField;
