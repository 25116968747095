/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import {
  TopToolbar, CreateButton, RefreshButton, FunctionField,
  EditButton, List, Datagrid, TextField, BooleanField,
  ReferenceManyField, SingleFieldList, ChipField, Loading,
  ReferenceInput, SelectInput,
} from 'react-admin';
import { PropTypes } from 'prop-types';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { AmplifyFilter } from 'react-admin-amplify';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import { stripUUID, useAmplifyMigration } from '../Utils';
import { updateCareer } from '../graphql/mutations';
import { minimalListCareers } from '../graphql/extended/queries';

const CareerActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

CareerActions.propTypes = {
  basePath: PropTypes.string,
};

CareerActions.defaultProps = {
  basePath: '/careersByName',
};

const migrations = {
  0: (items) => items
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateCareer, { input: { id: item.id, t: 'Careers', version: 0 } }))
        .then(() => {
        });
    }, Promise.resolve()),
  1: (items) => items
    .filter((i) => i?.version < 1)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateCareer, { input: { id: item.id, description: item.description.replace(/(<([^>]+)>)/gi, ''), version: 1 } }))
        .then(() => {
        });
    }, Promise.resolve()),
  2: (items) => items
    .filter((i) => i?.version < 2)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      if (!item?.video || item?.video.includes('careers/videos/')) { // Video in correct location, increase version and continue
        return API.graphql(graphqlOperation(updateCareer, { input: { id: item.id, version: 2 } }))
          .then(() => {
          });
      } // Update path and move file
      return API.graphql(graphqlOperation(updateCareer, { input: { id: item.id, video: `careers/videos/${item.video}`, version: 2 } }))
        .then(async () => {
          await Storage.copy({ key: item.video }, { key: `careers/videos/${item.video}` });
        });
    }, Promise.resolve()),
};

const CareersFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AmplifyFilter {...props}>
    <ReferenceInput
      source="listCareersByCategoryByName.categoryID"
      reference="categories"
      label="Category"
      alwaysOn
      resettable
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </AmplifyFilter>
);

const CareerList = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState('listCareersByName');
  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListCareers, 'listCareers', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      filters={<CareersFilter setQuery={setQuery} />}
      actions={<CareerActions />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Career" sortBy="listCareersByName" />
        <TextField source="category.name" label="Category" />
        <ReferenceManyField reference="relatedCareersBridges" target="careerss" label="Related careers">
          <SingleFieldList>
            <ChipField source="relatedCareer.name" />
          </SingleFieldList>
        </ReferenceManyField>
        <FunctionField source="video" label="Video" render={(r) => (r.video ? stripUUID(r.video) : '')} />
        <BooleanField source="archive" label="Archived" TrueIcon={Check} FalseIcon={Clear} looseValue valueLabelTrue="This career is archived" valueLabelFalse="This career is active" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CareerList;
