import React from 'react';
import {
  TopToolbar, CreateButton, RefreshButton,
  EditButton, List, Datagrid, TextField, DateField,
  FunctionField, Loading,
} from 'react-admin';
import { PropTypes } from 'prop-types';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { stripUUID, useAmplifyMigration } from '../Utils';
import { minimalListCategories } from '../graphql/extended/queries';
import { updateCategory } from '../graphql/mutations';

const CategoryActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

CategoryActions.propTypes = {
  basePath: PropTypes.string.isRequired,
};

const migrations = {
  0: (items) => items.map(async (item) => {
    if (item?.version <= 0) {
      await API.graphql(graphqlOperation(updateCategory, { input: { id: item.id, t: 'Categories', version: 0 } }));
    }
  }),
  1: (items) => items.map(async (item) => {
    if (item?.version <= 1) {
      const sublines = [
        {
          name: 'The Do-er',
          subline: 'Realisitc',
        },
        {
          name: 'The Thinker',
          subline: 'Investigative',
        },
        {
          name: 'The Creator',
          subline: 'Artistic',
        },
        {
          name: 'The Helper',
          subline: 'Social',
        },
        {
          name: 'The Persuader',
          subline: 'Enterprising',
        },
        {
          name: 'The Organiser',
          subline: 'Conventional',
        },
      ];
      const sublineOption = sublines.filter((option) => item.name === option.name);
      if (sublineOption.length > 0) {
        await API.graphql(graphqlOperation(updateCategory,
          { input: { id: item.id, subline: sublineOption[0].subline, version: 1 } }));
      } else {
        await API.graphql(graphqlOperation(updateCategory,
          { input: { id: item.id, version: 1 } }));
      }
    }
  }),
  2: (items) => items
    .filter((i) => i?.version < 2)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      if (!item?.icon || item?.icon.includes('categories/icons/')) { // Icon in correct location, increase version and continue
        return API.graphql(graphqlOperation(updateCategory, { input: { id: item.id, version: 2 } }))
          .then(() => {
          });
      } // Update path and move file
      return API.graphql(graphqlOperation(updateCategory, { input: { id: item.id, icon: `categories/icons/${item.icon}`, version: 2 } }))
        .then(async () => {
          await Storage.copy({ key: item.icon }, { key: `categories/icons/${item.icon}` });
        });
    }, Promise.resolve()),
  3: (items) => items
    .filter((i) => i?.version < 3)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      if (!item?.video || item?.video.includes('categories/videos/')) { // Video in correct location, increase version and continue
        return API.graphql(graphqlOperation(updateCategory, { input: { id: item.id, version: 3 } }))
          .then(() => {
          });
      } // Update path and move file
      return API.graphql(graphqlOperation(updateCategory, { input: { id: item.id, video: `categories/videos/${item.video}`, version: 3 } }))
        .then(async () => {
          await Storage.copy({ key: item.video }, { key: `categories/videos/${item.video}` });
        });
    }, Promise.resolve()),
};

const CategoryList = (props) => {
  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListCategories, 'listCategories', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      actions={<CategoryActions />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="colour" />
        <FunctionField source="icon" label="Icon" render={(r) => stripUUID(r.icon)} />
        <FunctionField source="video" label="Video" render={(r) => (r.video ? stripUUID(r.video) : '')} />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CategoryList;
