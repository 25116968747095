// in App.js
import { Amplify } from '@aws-amplify/core';
import React from 'react';
import {
  Resource,
  // ListGuesser,
  // EditGuesser,
} from 'react-admin';
import {
  AmplifyAdmin,
  CognitoGroupList,
  // CognitoUserList,
  // CognitoUserShow,
} from 'react-admin-amplify';
import {
  Person, Group, Category, ContactSupport,
  Grade, School, AccountBalance, ControlCamera, Feedback, Timeline,
  Message, Forum, Place,
} from '@material-ui/icons';
import awsExports from './aws-exports';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import * as extendedQueries from './graphql/extended/queries';
import * as extendedMutations from './graphql/extended/mutations';
// import Sandbox from './Sandbox';
import { CategoryList, CategoryCreate, CategoryEdit } from './Categories';
import {
  CareerList, CareerCreate, CareerEdit,
} from './Careers';
import { SchoolList, SchoolCreate, SchoolEdit } from './Schools';
import { GradeList, GradeCreate, GradeEdit } from './Grades';
import { SponsorList, SponsorCreate, SponsorEdit } from './Sponsors';
import { CareerCentreList, CareerCentreCreate, CareerCentreEdit } from './CareerCentres';
import { QuestionList, QuestionCreate, QuestionEdit } from './Questions';
import { FeedbackQuestionList, FeedbackQuestionCreate, FeedbackQuestionEdit } from './FeedbackQuestions';
import ResultList from './Results/List';
import ResponsesList from './Responses/List';
import FeedbackList from './Feedback/List';
import SponsorCodesList from './SponsorCodes/List';
import StudentsList from './Students/List';
import { SponsorStudentBridgesList, SponsorStudentBridgesStudentList } from './SponsorStudentBridges';
import { CognitoUserShowCustom, CognitoUserListCustom } from './Users';
import Login from './Authentication/Login';
import uploadProgressReducer from './uploadProgressReducer';
import UploadProgress from './UploadProgress/UploadProgress';
import { RelatedCareerList, RelatedCareerCreate, RelatedCareerEdit } from './RelatedCareers';

Amplify.configure(awsExports); // Configure Amplify the usual way

function App() {
  return (
    <AmplifyAdmin // Replace the Admin component of react-admin
      loginPage={Login}
      operations={{
        queries: {
          ...queries,
          ...extendedQueries,
        },
        // queries,
        mutations: {
          ...mutations,
          ...extendedMutations,
        },
      }} // Pass the queries and mutations
      options={{
        authGroups: ['admin'],
        enableAdminQueries: true,
      }}
      customReducers={{ fileProgress: uploadProgressReducer }}
    >
      <Resource
        name="careers"
      />
      <Resource
        name="careersByName"
        options={{ label: 'Careers' }}
        list={CareerList}
        create={CareerCreate}
        edit={CareerEdit}
      />
      <Resource
        name="relatedCareers"
      />
      <Resource name="relatedCareersBridges" />
      <Resource
        name="relatedCareersByName"
        icon={ControlCamera}
        options={{ label: 'Related Careers' }}
        list={RelatedCareerList}
        create={RelatedCareerCreate}
        edit={RelatedCareerEdit}
      />
      <Resource
        icon={Category}
        name="categories"
        list={CategoryList}
        create={CategoryCreate}
        edit={CategoryEdit}
      />
      {/* shim to make requests work. */}
      {/* <Resource name="categoryy" /> */}
      {/* <Resource name="MyCustomPage" list={Sandbox} /> */}
      {/* Set the resources as you would do within Admin component */}
      <Resource
        icon={Person}
        name="cognitoUsers"
        options={{ label: 'Users' }}
        list={CognitoUserListCustom}
        show={CognitoUserShowCustom}
      />
      <Resource
        icon={Group}
        name="cognitoGroups"
        options={{ label: 'User Groups' }}
        list={CognitoGroupList}
      />
      <Resource
        icon={ContactSupport}
        options={{ label: 'Questions' }}
        name="questions"
        list={QuestionList}
        create={QuestionCreate}
        edit={QuestionEdit}
      />
      <Resource
        icon={Feedback}
        options={{ label: 'Feedback Questions' }}
        name="feedbackQuestions"
        list={FeedbackQuestionList}
        create={FeedbackQuestionCreate}
        edit={FeedbackQuestionEdit}
      />
      <Resource
        icon={Forum}
        name="feedbacks"
        options={{ label: 'Feedback' }}
        list={FeedbackList}
      />
      <Resource name="feedbacksByResultId" />
      <Resource
        icon={School}
        name="schools"
        list={SchoolList}
        create={SchoolCreate}
        edit={SchoolEdit}
      />
      <Resource
        icon={Grade}
        name="grades"
        list={GradeList}
        create={GradeCreate}
        edit={GradeEdit}
      />
      <Resource
        icon={AccountBalance}
        name="sponsors"
        list={SponsorList}
        create={SponsorCreate}
        edit={SponsorEdit}
      />
      <Resource
        icon={Place}
        name="careerCentres"
        options={{ label: 'Career Centres' }}
        list={CareerCentreList}
        create={CareerCentreCreate}
        edit={CareerCentreEdit}
      />
      <Resource
        icon={Timeline}
        name="results"
        list={ResultList}
      />
      <Resource name="resultsByStudentId" />
      <Resource name="resultCategories" />
      <Resource name="resultCategoriesByResultId" />
      <Resource
        icon={Message}
        name="responses"
        list={ResponsesList}
      />
      <Resource name="responsesByResultId" />
      <Resource
        name="sponsorStudentBridges"
        list={SponsorStudentBridgesList}
      />
      <Resource name="sponsorStudentBridgesByStudentId" />
      <Resource
        name="sponsorStudentBridgesBySponsorId"
        list={SponsorStudentBridgesStudentList}
      />
      <Resource
        icon={Forum}
        name="sponsorCodes"
        options={{ label: 'Sponsor Codes' }}
        list={SponsorCodesList}
      />
      <Resource
        icon={Forum}
        name="students"
        options={{ label: 'Student' }}
        list={StudentsList}
      />
      <UploadProgress />
    </AmplifyAdmin>
  );
}

export default App;
