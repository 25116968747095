/* eslint-disable no-param-reassign */
import React from 'react';
import {
  Edit, SimpleForm, SelectInput, NumberInput, TextInput,
} from 'react-admin';

const EditFeedbackQuestion = (props) => (
  <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    title="Edit feedback question"
    resource="feedbackQuestions"
  >
    <SimpleForm>
      <NumberInput source="number" min={1} max={100} step={1} />
      <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
      <SelectInput
        source="type"
        choices={[
          { id: 'RATING', name: 'Rating' },
          { id: 'TEXT', name: 'Text' },
          { id: 'UPDOWN', name: 'Updown' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default EditFeedbackQuestion;
