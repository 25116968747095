/**
 * This file houses queries we add for accomodating shortcomines of react-admin-amplify.
 */

/**
 * Had to add this for Category edit to work.
 */
// eslint-disable-next-line import/prefer-default-export
export const listResultsByStudentId = /* GraphQL */ `
  query ListResultsByStudentId(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResultsByStudentId(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        sponsorStudentBridgeID
        sponsorStudentBridge {
          code
        }
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategorie = /* GraphQL */ `
  query GetCategorie($id: ID!) {
    getCategorie: getCategory(id: $id) {
      id
      name
      subline
      description
      colour
      icon
      video
      careers {
        nextToken
      }
      questions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCareers = /* GraphQL */ `
  query ListCareers(
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        categoryID
        archive
        video
        createdAt
        updatedAt
        category {
          id
          name
          subline
          description
          colour
          createdAt
          updatedAt
        }
        relatedCareersBridges {
          items {
            id
            relatedCareer {
              id
              name
              description
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCareersByName = /* GraphQL */ `
  query ListCareersByName(
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareersByName(
      t: "Careers"
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
        category {
          id
          name
          subline
          description
          colour
          createdAt
          updatedAt
        }
        relatedCareersBridges {
          items {
            id
            relatedCareer {
              id
              name
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listSponsors = /* GraphQL */ `
  query ListSponsors(
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        license_count
        code
        content
        createdAt
        updatedAt
        contact{
          emailAddress
          websiteURL
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        categoryID
        type
        set
        number
        createdAt
        updatedAt
        category {
          id
          name
          subline
          description
          colour
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) {
      id
      name
      description
      categoryID
      archive
      video
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        createdAt
        updatedAt
      }
      relatedCareersBridges {
        items {
          id
          relatedCareer {
            id
            name
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * This query makes the related careers show up on the career list.
 */
export const listRelatedCareersBridgesByCareerId = /* GraphQL */ `
  query listRelatedCareersBridgesByCareerId(
    $careerss: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRelatedCareersBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelatedCareersBridgesByCareerId(
      careerID: $careerss
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        careerID
        relatedCareerID
        createdAt
        updatedAt
        relatedCareer {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listRelatedCareersByName = /* GraphQL */ `
  query ListRelatedCareersByName(
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRelatedCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelatedCareersByName(
      type: "relatedCareers"
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        description
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const minimalListCareers = /* GraphQL */ `
query ListCareers(
  $filter: ModelCareerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCareers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      version
      description
      video
    }
    nextToken
  }
}
`;
export const minimalListRelatedCareers = /* GraphQL */ `
  query ListRelatedCareers(
    $filter: ModelRelatedCareerFilterInput
    # $limit: Int
    $nextToken: String
  ) {
    listRelatedCareers(filter: $filter, limit: 1000, nextToken: $nextToken) {
      items {
        id
        version
        description
      }
      nextToken
    }
  }
`;
export const minimalListQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        version
        number
        description
      }
      nextToken
    }
  }
`;
export const minimalListFeedbackQuestions = /* GraphQL */ `
  query ListFeedbackQuestions(
    $filter: ModelFeedbackQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbackQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        version
        description
      }
      nextToken
    }
  }
`;
export const minimalListSponsors = /* GraphQL */ `
  query ListSponsors(
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        version
        content
        logo
      }
      nextToken
    }
  }
`;
export const minimalListCareerCentres = /* GraphQL */ `
query ListCareerCentres(
  $filter: ModelCareerCentreFilterInput
  $limit: Int
  $nextToken: String
) {
  listCareerCentres(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      version
    }
    nextToken
  }
}
`;
export const minimalListFeedbacks = /* GraphQL */ `
query ListFeedbacks(
  $filter: ModelFeedbackFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      version
      result {
        studentID
        sponsorStudentBridge {
          sponsorID
        }
      }
    }
    nextToken
  }
}
`;
export const listQuestionsByNumber = /* GraphQL */ `
  query ListQuestionsByNumber(
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByNumber(
      t: "Questions"
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
        category {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listQuestionsBySet = /* GraphQL */ `
  query ListQuestionsBySet(
    $set: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsBySet(
      t: "Questions"
      set: $set
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
        category {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const maxQuestionSet = /* GraphQL */ `
  query ListQuestionsBySet(
    $set: ModelIntKeyConditionInput
  ) {
    listQuestionsBySet(
      t: "Questions"
      set: $set
      sortDirection: DESC
      limit: 1
    ) {
      items {
        id
        set
      }
    }
  }
`;
export const listQuestionsByDescription = /* GraphQL */ `
  query ListQuestionsByDescription(
    $description: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByDescription(
      t: "Questions"
      description: $description
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        number
        t
        version
        createdAt
        updatedAt
        category {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const minimalListCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        version
        name
        icon
        video
      }
      nextToken
    }
  }
`;
export const minimalListSponsorCodes = /* GraphQL */ `
query ListSponsorCodes(
  $filter: ModelSponsorCodeFilterInput
  # $limit: Int
  $nextToken: String
) {
  listSponsorCodes(filter: $filter, limit: 1000, nextToken: $nextToken) {
    items {
      id
      version
      code
    }
    nextToken
  }
}
`;
export const listCategoriesByName = /* GraphQL */ `
  query ListCategoriesByName(
    $t: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriesByName(
      t: "Categories"
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByCategoryByNumber = /* GraphQL */ `
  query QuestionsByCategoryByNumber(
    $categoryID: ID
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByCategoryByNumber(
      categoryID: $categoryID
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
        category {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const questionsByCategoryBySet = /* GraphQL */ `
  query QuestionsByCategoryBySet(
    $categoryID: ID
    $set: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByCategoryBySet(
      categoryID: $categoryID
      set: $set
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
        category {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const feedbackQuestionsByNumber = /* GraphQL */ `
  query FeedbackQuestionsByNumber(
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackQuestionsByNumber(
      t: "FeedbackQuestions"
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        type
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listResults = /* GraphQL */ `
  query ListResults(
    $filter: ModelResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
        resultCategories {
          items {
            id
            score
            category {
              id
              name
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listResultCategories = /* GraphQL */ `
  query ListResultCategories(
    $filter: ModelResultCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResultCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        score
        categoryID
        category {
          id
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listResultCategoriesByResultId = /* GraphQL */ `
  query ListResultCategoriesByResultId(
    $resultID: ID
    $score: ModelIntKeyConditionInput
    $filter: ModelResultCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResultCategoriesByResultId(
      resultID: $resultID
      score: $score
      sortDirection: DESC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        score
        categoryID
        category {
          id
          name
          subline
          description
          colour
          icon
          video
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listResponsesByResultId = /* GraphQL */ `
  query ListResponsesByResultId(
    $resultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponsesByResultId(
      resultID: $resultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        questionID
        question {
          id
          description
          category {
            id
            name
          }
        }
        studentID
        sponsorStudentBridgeID
        response
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedback = /* GraphQL */ `
  query ListFeedback(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resultID
        feedbackQuestionID
        feedbackQuestion {
          id
          description
          type
        }
        feedback
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedbacksByResultId = /* GraphQL */ `
  query ListFeedbacksByResultId(
    $resultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacksByResultId(
      resultID: $resultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        result {
          studentID
          student {
            name
            surname
          }
          sponsorStudentBridgeID
          sponsorStudentBridge {
            sponsorID
            sponsor {
              name
            }
          }
        }
        feedbackQuestionID
        feedbackQuestion {
          id
          description
          type
        }
        feedback
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resultID
        feedbackQuestionID
        feedbackQuestion {
          id
          description
          type
        }
        feedback
        sponsor {
          name
        }
        student {
          name
          surname
        }
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedbacksBySponsorId = /* GraphQL */ `
  query ListFeedbacksBySponsorId(
    $sponsorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacksBySponsorId(
      sponsorID: $sponsorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        feedbackQuestionID
        feedbackQuestion {
          id
          description
          type
        }
        sponsorID
        sponsor {
          name
        }
        studentID
        student {
          name
          surname
        }
        feedback
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSponsorStudentBridges = /* GraphQL */ `
  query ListSponsorStudentBridges(
    $filter: ModelSponsorStudentBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStudentBridges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorID
        sponsor {
          id
          name
        }
        studentID
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSponsorStudentBridgesByStudentId = /* GraphQL */ `
  query ListSponsorStudentBridgesByStudentId(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorStudentBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStudentBridgesByStudentId(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorID
        sponsor {
          id
          name
        }
        studentID
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resultID
        questionID
        question {
          id
          description
          category {
            id
            name
            subline
            description
            icon
            colour
            video
          }
        }
        studentID
        sponsorStudentBridgeID
        response
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSponsorCodesBySponsor = /* GraphQL */ `
  query ListSponsorCodesBySponsor(
    $sponsorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorCodesBySponsor(
      sponsorID: $sponsorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorID
        code
        used
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSponsorStudentBridgesBySponsorId = /* GraphQL */ `
  query ListSponsorStudentBridgesBySponsorId(
    $sponsorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorStudentBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStudentBridgesBySponsorId(
      sponsorID: $sponsorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
      id
      sponsorID
      studentID
      student {
        id
        userID
        name
        surname
        username
        age
        gradeID
        grade {
          id
          name
        }
        schoolID
        school {
          id
          name
        }
        email
        phone_number
      }
      code
      createdAt
      updatedAt
      result {
        items {
          id
          resultCategories {
            items {
              score
              category {
                name
              }
            }
          }
          createdAt
        }
      }
      sponsor {
        name
      }
    }
      nextToken
    }
  }
`;
export const listStudentsByGradeId = /* GraphQL */ `
  query ListStudentsByGradeId(
    $gradeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentsByGradeId(
      gradeID: $gradeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        grade {
          id
          name
        }
        schoolID
        school {
          id
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStudentsBySchoolId = /* GraphQL */ `
  query ListStudentsBySchoolId(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentsBySchoolId(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        grade {
          id
          name
        }
        schoolID
        school {
          id
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCareersByCategoryByName = /* GraphQL */ `
  query ListCareersByCategoryByName(
    $categoryID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareersByCategoryByName(
      categoryID: $categoryID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
        category {
          id
          name
          subline
          description
          colour
          createdAt
          updatedAt
        }
        relatedCareersBridges {
          items {
            id
            relatedCareer {
              id
              name
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listQuestionsBySetByNumber = /* GraphQL */ `
  query ListQuestionsBySetByNumber(
    $set: Int
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsBySetByNumber(
      set: $set
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
        category {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listCareerCentres = /* GraphQL */ `
  query ListCareerCentres(
    $filter: ModelCareerCentreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareerCentres(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        t
        version
        createdAt
        updatedAt
        contact {
          phoneNumber
        }
      }
      nextToken
    }
  }
`;
