import React from 'react';
import {
  Create, SimpleForm, SelectInput, NumberInput, TextInput,
} from 'react-admin';

const CreateFeedbackQuestion = (props) => (
  <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    resource="FeedbackQuestions"
    transform={(data) => ({ ...data, t: 'FeedbackQuestions', version: 0 })}
  >
    <SimpleForm redirect="list">
      <NumberInput source="number" min={1} max={100} step={1} />
      <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
      <SelectInput
        source="type"
        choices={[
          { id: 'RATING', name: 'Rating' },
          { id: 'TEXT', name: 'Text' },
          { id: 'UPDOWN', name: 'Updown' },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default CreateFeedbackQuestion;
