/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      description
      categoryID
      type
      set
      number
      t
      version
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      description
      categoryID
      type
      set
      number
      t
      version
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      description
      categoryID
      type
      set
      number
      t
      version
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      userID
      username
      name
      surname
      age
      email
      phone_number
      gradeID
      schoolID
      sponsors {
        nextToken
      }
      createdAt
      updatedAt
      grade {
        id
        name
        status
        createdAt
        updatedAt
      }
      school {
        id
        name
        status
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      userID
      username
      name
      surname
      age
      email
      phone_number
      gradeID
      schoolID
      sponsors {
        nextToken
      }
      createdAt
      updatedAt
      grade {
        id
        name
        status
        createdAt
        updatedAt
      }
      school {
        id
        name
        status
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      userID
      username
      name
      surname
      age
      email
      phone_number
      gradeID
      schoolID
      sponsors {
        nextToken
      }
      createdAt
      updatedAt
      grade {
        id
        name
        status
        createdAt
        updatedAt
      }
      school {
        id
        name
        status
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSponsorStudentBridge = /* GraphQL */ `
  mutation CreateSponsorStudentBridge(
    $input: CreateSponsorStudentBridgeInput!
    $condition: ModelSponsorStudentBridgeConditionInput
  ) {
    createSponsorStudentBridge(input: $input, condition: $condition) {
      id
      sponsorID
      studentID
      code
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      result {
        nextToken
      }
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSponsorStudentBridge = /* GraphQL */ `
  mutation UpdateSponsorStudentBridge(
    $input: UpdateSponsorStudentBridgeInput!
    $condition: ModelSponsorStudentBridgeConditionInput
  ) {
    updateSponsorStudentBridge(input: $input, condition: $condition) {
      id
      sponsorID
      studentID
      code
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      result {
        nextToken
      }
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSponsorStudentBridge = /* GraphQL */ `
  mutation DeleteSponsorStudentBridge(
    $input: DeleteSponsorStudentBridgeInput!
    $condition: ModelSponsorStudentBridgeConditionInput
  ) {
    deleteSponsorStudentBridge(input: $input, condition: $condition) {
      id
      sponsorID
      studentID
      code
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      result {
        nextToken
      }
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const createResults = /* GraphQL */ `
  mutation CreateResults(
    $input: CreateResultsInput!
    $condition: ModelResultsConditionInput
  ) {
    createResults(input: $input, condition: $condition) {
      id
      studentID
      sponsorStudentBridgeID
      file
      progress
      expiryDate
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      sponsorStudentBridge {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      responses {
        nextToken
      }
      feedback {
        nextToken
      }
      resultCategories {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateResults = /* GraphQL */ `
  mutation UpdateResults(
    $input: UpdateResultsInput!
    $condition: ModelResultsConditionInput
  ) {
    updateResults(input: $input, condition: $condition) {
      id
      studentID
      sponsorStudentBridgeID
      file
      progress
      expiryDate
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      sponsorStudentBridge {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      responses {
        nextToken
      }
      feedback {
        nextToken
      }
      resultCategories {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteResults = /* GraphQL */ `
  mutation DeleteResults(
    $input: DeleteResultsInput!
    $condition: ModelResultsConditionInput
  ) {
    deleteResults(input: $input, condition: $condition) {
      id
      studentID
      sponsorStudentBridgeID
      file
      progress
      expiryDate
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      sponsorStudentBridge {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      responses {
        nextToken
      }
      feedback {
        nextToken
      }
      resultCategories {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createResultCategory = /* GraphQL */ `
  mutation CreateResultCategory(
    $input: CreateResultCategoryInput!
    $condition: ModelResultCategoryConditionInput
  ) {
    createResultCategory(input: $input, condition: $condition) {
      id
      resultID
      score
      categoryID
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateResultCategory = /* GraphQL */ `
  mutation UpdateResultCategory(
    $input: UpdateResultCategoryInput!
    $condition: ModelResultCategoryConditionInput
  ) {
    updateResultCategory(input: $input, condition: $condition) {
      id
      resultID
      score
      categoryID
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteResultCategory = /* GraphQL */ `
  mutation DeleteResultCategory(
    $input: DeleteResultCategoryInput!
    $condition: ModelResultCategoryConditionInput
  ) {
    deleteResultCategory(input: $input, condition: $condition) {
      id
      resultID
      score
      categoryID
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFeedbackQuestion = /* GraphQL */ `
  mutation CreateFeedbackQuestion(
    $input: CreateFeedbackQuestionInput!
    $condition: ModelFeedbackQuestionConditionInput
  ) {
    createFeedbackQuestion(input: $input, condition: $condition) {
      id
      description
      type
      number
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedbackQuestion = /* GraphQL */ `
  mutation UpdateFeedbackQuestion(
    $input: UpdateFeedbackQuestionInput!
    $condition: ModelFeedbackQuestionConditionInput
  ) {
    updateFeedbackQuestion(input: $input, condition: $condition) {
      id
      description
      type
      number
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedbackQuestion = /* GraphQL */ `
  mutation DeleteFeedbackQuestion(
    $input: DeleteFeedbackQuestionInput!
    $condition: ModelFeedbackQuestionConditionInput
  ) {
    deleteFeedbackQuestion(input: $input, condition: $condition) {
      id
      description
      type
      number
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      resultID
      feedbackQuestionID
      sponsorID
      studentID
      feedback
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      feedbackQuestion {
        id
        description
        type
        number
        t
        version
        createdAt
        updatedAt
      }
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      t
      version
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      resultID
      feedbackQuestionID
      sponsorID
      studentID
      feedback
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      feedbackQuestion {
        id
        description
        type
        number
        t
        version
        createdAt
        updatedAt
      }
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      t
      version
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      resultID
      feedbackQuestionID
      sponsorID
      studentID
      feedback
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      feedbackQuestion {
        id
        description
        type
        number
        t
        version
        createdAt
        updatedAt
      }
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      t
      version
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      resultID
      questionID
      studentID
      sponsorStudentBridgeID
      response
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      question {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      sponsorStudentBridge {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      id
      resultID
      questionID
      studentID
      sponsorStudentBridgeID
      response
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      question {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      sponsorStudentBridge {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      id
      resultID
      questionID
      studentID
      sponsorStudentBridgeID
      response
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      question {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      sponsorStudentBridge {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSponsorCode = /* GraphQL */ `
  mutation CreateSponsorCode(
    $input: CreateSponsorCodeInput!
    $condition: ModelSponsorCodeConditionInput
  ) {
    createSponsorCode(input: $input, condition: $condition) {
      id
      sponsorID
      code
      paymentID
      used
      t
      version
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSponsorCode = /* GraphQL */ `
  mutation UpdateSponsorCode(
    $input: UpdateSponsorCodeInput!
    $condition: ModelSponsorCodeConditionInput
  ) {
    updateSponsorCode(input: $input, condition: $condition) {
      id
      sponsorID
      code
      paymentID
      used
      t
      version
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSponsorCode = /* GraphQL */ `
  mutation DeleteSponsorCode(
    $input: DeleteSponsorCodeInput!
    $condition: ModelSponsorCodeConditionInput
  ) {
    deleteSponsorCode(input: $input, condition: $condition) {
      id
      sponsorID
      code
      paymentID
      used
      t
      version
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFavouritedCareer = /* GraphQL */ `
  mutation CreateFavouritedCareer(
    $input: CreateFavouritedCareerInput!
    $condition: ModelFavouritedCareerConditionInput
  ) {
    createFavouritedCareer(input: $input, condition: $condition) {
      id
      resultID
      careerID
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      career {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFavouritedCareer = /* GraphQL */ `
  mutation UpdateFavouritedCareer(
    $input: UpdateFavouritedCareerInput!
    $condition: ModelFavouritedCareerConditionInput
  ) {
    updateFavouritedCareer(input: $input, condition: $condition) {
      id
      resultID
      careerID
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      career {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFavouritedCareer = /* GraphQL */ `
  mutation DeleteFavouritedCareer(
    $input: DeleteFavouritedCareerInput!
    $condition: ModelFavouritedCareerConditionInput
  ) {
    deleteFavouritedCareer(input: $input, condition: $condition) {
      id
      resultID
      careerID
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      career {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      subline
      description
      colour
      icon
      questions {
        nextToken
      }
      video
      t
      version
      createdAt
      updatedAt
      careers {
        nextToken
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      subline
      description
      colour
      icon
      questions {
        nextToken
      }
      video
      t
      version
      createdAt
      updatedAt
      careers {
        nextToken
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      subline
      description
      colour
      icon
      questions {
        nextToken
      }
      video
      t
      version
      createdAt
      updatedAt
      careers {
        nextToken
      }
    }
  }
`;
export const createCareer = /* GraphQL */ `
  mutation CreateCareer(
    $input: CreateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    createCareer(input: $input, condition: $condition) {
      id
      name
      description
      categoryID
      archive
      video
      t
      version
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      relatedCareersBridges {
        nextToken
      }
    }
  }
`;
export const updateCareer = /* GraphQL */ `
  mutation UpdateCareer(
    $input: UpdateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    updateCareer(input: $input, condition: $condition) {
      id
      name
      description
      categoryID
      archive
      video
      t
      version
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      relatedCareersBridges {
        nextToken
      }
    }
  }
`;
export const deleteCareer = /* GraphQL */ `
  mutation DeleteCareer(
    $input: DeleteCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    deleteCareer(input: $input, condition: $condition) {
      id
      name
      description
      categoryID
      archive
      video
      t
      version
      createdAt
      updatedAt
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      relatedCareersBridges {
        nextToken
      }
    }
  }
`;
export const createRelatedCareer = /* GraphQL */ `
  mutation CreateRelatedCareer(
    $input: CreateRelatedCareerInput!
    $condition: ModelRelatedCareerConditionInput
  ) {
    createRelatedCareer(input: $input, condition: $condition) {
      id
      type
      name
      description
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateRelatedCareer = /* GraphQL */ `
  mutation UpdateRelatedCareer(
    $input: UpdateRelatedCareerInput!
    $condition: ModelRelatedCareerConditionInput
  ) {
    updateRelatedCareer(input: $input, condition: $condition) {
      id
      type
      name
      description
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteRelatedCareer = /* GraphQL */ `
  mutation DeleteRelatedCareer(
    $input: DeleteRelatedCareerInput!
    $condition: ModelRelatedCareerConditionInput
  ) {
    deleteRelatedCareer(input: $input, condition: $condition) {
      id
      type
      name
      description
      version
      createdAt
      updatedAt
    }
  }
`;
export const createRelatedCareersBridge = /* GraphQL */ `
  mutation CreateRelatedCareersBridge(
    $input: CreateRelatedCareersBridgeInput!
    $condition: ModelRelatedCareersBridgeConditionInput
  ) {
    createRelatedCareersBridge(input: $input, condition: $condition) {
      id
      careerID
      relatedCareerID
      createdAt
      updatedAt
      career {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
      relatedCareer {
        id
        type
        name
        description
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateRelatedCareersBridge = /* GraphQL */ `
  mutation UpdateRelatedCareersBridge(
    $input: UpdateRelatedCareersBridgeInput!
    $condition: ModelRelatedCareersBridgeConditionInput
  ) {
    updateRelatedCareersBridge(input: $input, condition: $condition) {
      id
      careerID
      relatedCareerID
      createdAt
      updatedAt
      career {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
      relatedCareer {
        id
        type
        name
        description
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteRelatedCareersBridge = /* GraphQL */ `
  mutation DeleteRelatedCareersBridge(
    $input: DeleteRelatedCareersBridgeInput!
    $condition: ModelRelatedCareersBridgeConditionInput
  ) {
    deleteRelatedCareersBridge(input: $input, condition: $condition) {
      id
      careerID
      relatedCareerID
      createdAt
      updatedAt
      career {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
      relatedCareer {
        id
        type
        name
        description
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const createGrade = /* GraphQL */ `
  mutation CreateGrade(
    $input: CreateGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    createGrade(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGrade = /* GraphQL */ `
  mutation UpdateGrade(
    $input: UpdateGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    updateGrade(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGrade = /* GraphQL */ `
  mutation DeleteGrade(
    $input: DeleteGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    deleteGrade(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSponsor = /* GraphQL */ `
  mutation CreateSponsor(
    $input: CreateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    createSponsor(input: $input, condition: $condition) {
      id
      name
      logo
      license_count
      code
      content
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      students {
        nextToken
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateSponsor = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
      name
      logo
      license_count
      code
      content
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      students {
        nextToken
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteSponsor = /* GraphQL */ `
  mutation DeleteSponsor(
    $input: DeleteSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    deleteSponsor(input: $input, condition: $condition) {
      id
      name
      logo
      license_count
      code
      content
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      students {
        nextToken
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const createCareerCentre = /* GraphQL */ `
  mutation CreateCareerCentre(
    $input: CreateCareerCentreInput!
    $condition: ModelCareerCentreConditionInput
  ) {
    createCareerCentre(input: $input, condition: $condition) {
      id
      name
      description
      image
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        name
        phoneNumber
        phoneNumber2
        emailAddress
        websiteURL
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateCareerCentre = /* GraphQL */ `
  mutation UpdateCareerCentre(
    $input: UpdateCareerCentreInput!
    $condition: ModelCareerCentreConditionInput
  ) {
    updateCareerCentre(input: $input, condition: $condition) {
      id
      name
      description
      image
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        name
        phoneNumber
        phoneNumber2
        emailAddress
        websiteURL
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteCareerCentre = /* GraphQL */ `
  mutation DeleteCareerCentre(
    $input: DeleteCareerCentreInput!
    $condition: ModelCareerCentreConditionInput
  ) {
    deleteCareerCentre(input: $input, condition: $condition) {
      id
      name
      description
      image
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        name
        phoneNumber
        phoneNumber2
        emailAddress
        websiteURL
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
