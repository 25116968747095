import React from 'react';
import {
  TopToolbar,
  RefreshButton,
  List,
  Datagrid,
  TextField,
  FunctionField,
  Loading,
} from 'react-admin';
import { API, graphqlOperation } from 'aws-amplify';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { listSponsorStudentBridgesByCode } from '../graphql/queries';
import { useAmplifyMigration } from '../Utils';
import { updateSponsorCode } from '../graphql/mutations';
import { minimalListSponsorCodes } from '../graphql/extended/queries';

const SponsorCodesActions = () => (
  <TopToolbar>
    <RefreshButton />
  </TopToolbar>
);

const SponsorCodesList = (props) => {
  const migrations = {
    0: (items) => items
      .reduce(async (previousPromise, item) => {
        await previousPromise;
        return API.graphql(graphqlOperation(updateSponsorCode, { input: { id: item.id, t: 'SponsorCodes', version: 0 } }))
          .then(() => {
          });
      }, Promise.resolve()),
    1: (items) => items
      .filter((i) => i?.version < 1)
      .reduce(async (previousPromise, item) => {
        await previousPromise;
        let used = '0';
        const usedCodesData = await API.graphql(graphqlOperation(
          listSponsorStudentBridgesByCode, { code: item.code },
        ));
        if (usedCodesData.data.listSponsorStudentBridgesByCode.items.length > 0) {
          used = '1';
        }
        return API.graphql(graphqlOperation(updateSponsorCode, {
          input: {
            id: item.id, used, version: 1, code: item.code,
          },
        }))
          .then(() => {
          });
      }, Promise.resolve()),
  };

  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListSponsorCodes, 'listSponsorCodes', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      actions={<SponsorCodesActions />}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="code" />
        <FunctionField
          label="Used"
          render={(record) => {
            if (record.used === '0') {
              return <Cancel style={{ fill: '#C92726' }} />;
            }
            return <CheckCircle style={{ fill: '#489844' }} />;
          }}
        />
      </Datagrid>
    </List>
  );
};

export default SponsorCodesList;
