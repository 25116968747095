import React from 'react';
import { Button } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

const BackButton = () => {
  const history = useHistory();
  return (
  // eslint-disable-next-line react/destructuring-assignment
    <Button
      startIcon={<ArrowBack />}
      onClick={() => { history.goBack(); }}
      label="Back"
    />
  );
};

export default BackButton;
