/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import {
  Create, SimpleForm, TextInput, ImageInput, NumberInput,
  useMutation, ImageField, useRedirect, useNotify,
} from 'react-admin';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

const CreateSponsor = (props) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();

  const generateCodes = (sponsorID, code, total) => {
    if (total !== 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < total; i++) {
        const studentCode = code + (i + 1).toString();
        mutate({
          type: 'create',
          resource: 'SponsorCodes',
          payload: {
            data: {
              sponsorID,
              code: studentCode,
              used: '0',
              t: 'SponsorCodes',
              version: 0,
            },
          },
        }, { returnPromise: true });
      }
    }
  };

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.students;

      // logo
      const { title: logoTitle } = values.logo;
      const uniqueLogoTitle = `sponsors/logos/${uuidv4()}-${logoTitle}`;
      await Storage.put(uniqueLogoTitle, values.logo.rawFile, {
        level: 'public',
        contentType: values.logo.rawFile.type,
      });
      values.logo = uniqueLogoTitle;

      values.t = 'Sponsors';
      values.version = 0;

      // Sponsor Code
      if (values.code.length < 8) {
        notify('Sponsor code should be 8 or more characters in length', 'warning');
        return false;
      }

      try {
        const sponsor = await mutate({
          type: 'create',
          resource: 'Sponsors',
          payload: { data: values },
        }, { returnPromise: true });
        generateCodes(sponsor.data.id, values.code, values.license_count);
        redirect('/sponsors');
      } catch (error) {
        if (error?.body?.errors) {
          return error?.body?.errors;
        }
      }
    },
    [mutate],
  );

  return (
    <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      resource="Sponsors"
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <ImageInput source="logo" label="Logo" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <NumberInput source="license_count" min={0} step={1} initialValue={0} />
        <TextInput source="code" />
        <TextInput source="content" multiline style={{ width: '100%' }} minRows={2} />
        <TextInput source="address.addressLine1" label="Address Line 1" />
        <TextInput source="address.addressLine2" label="Address Line 2" />
        <TextInput source="address.suburb" label="Suburb" />
        <TextInput source="address.city" label="City" />
        <TextInput source="address.province" label="Province" />
        <TextInput source="contact.phoneNumber" label="Contact Phone Number" />
        <TextInput source="contact.emailAddress" label="Contact Email Address" />
        <TextInput source="contact.websiteURL" label="Website URL" />
      </SimpleForm>
    </Create>
  );
};

export default CreateSponsor;
