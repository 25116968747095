import React from 'react';
import {
  TopToolbar,
  RefreshButton,
  List,
  Datagrid,
  FunctionField,
  TextField,
  ShowButton,
  ExportButton,
} from 'react-admin';
import xlsx from 'json-as-xlsx';
import PropTypes from 'prop-types';

const sponsorStudentsExporter = async (props) => {
  const values = props.map((item) => {
    if (item.student.school === null) {
      // eslint-disable-next-line
      item.student.school = { name: '' };
    }
    if (item.student.grade === null) {
      // eslint-disable-next-line
      item.student.grade = { name: '' };
    }
    return item;
  });

  const getTopCategories = (items) => {
    const topCategories = items
      .sort((a, b) => parseInt(b.score, 10) - parseInt(a.score, 10)).slice(0, 3);
    if (topCategories.length > 2) { // Only show results that have 3 top categories
      return {
        category_1: `${topCategories[0].category.name}: ${topCategories[0].score}%`,
        category_2: `${topCategories[1].category.name}: ${topCategories[1].score}%`,
        category_3: `${topCategories[2].category.name}: ${topCategories[2].score}%`,
      };
    }
    return {};
  };

  //  sort results by created date
  values.map((value) => value.result.items.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  ));

  const flattened = [
    ...values.map((item) => ({
      name: item.student.name,
      surname: item.student.surname,
      email: item.student.email,
      phone: item.student.phone_number,
      age: item.student.age,
      school: item.student.school.name,
      grade: item.student.grade.name,
      results: getTopCategories(item.result.items[0].resultCategories.items),
      sponsor: item.sponsor.name,
    })),
  ];

  const sorted = flattened.sort((a, b) => {
    if (a.surname < b.surname) {
      return -1;
    }
    if (a.surname > b.surname) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const data = [
    {
      sheet: 'Sponsored Students',
      columns: [
        { label: 'Name', value: 'name' },
        { label: 'Surname', value: 'surname' },
        { label: 'Email', value: 'email' },
        { label: 'Phone', value: 'phone' },
        { label: 'Age', value: 'age' },
        { label: 'School', value: 'school' },
        { label: 'Grade', value: 'grade' },
        { label: 'Category 1', value: 'results.category_1' },
        { label: 'Category 2', value: 'results.category_2' },
        { label: 'Category 3', value: 'results.category_3' },
      ],
      content: sorted,
    },
  ];

  const settings = {
    fileName: `${sorted[0].sponsor} Students`,
    extraLength: 3,
    writeOptions: {},
  };
  xlsx(data, settings);
};

const SponsorStudentbridgesActions = () => (
  <TopToolbar>
    <RefreshButton />
    <ExportButton />
  </TopToolbar>
);

const SponsorStudentBridgesStudentList = (props) => {
  // eslint-disable-next-line react/prop-types
  const UserShowButton = ({ record }) => (
    // eslint-disable-next-line react/prop-types
    <ShowButton
      basePath="/cognitoUsers"
      label="Show"
      record={{ id: record.student.username.toLowerCase() }}
    />
  );

  return (
    <List
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      actions={<SponsorStudentbridgesActions />}
      bulkActionButtons={false}
      exporter={sponsorStudentsExporter}
    >
      <Datagrid>
        <FunctionField
          label="Names"
          render={(record) => `${record.student.name} ${record.student.surname}`}
        />
        <TextField source="student.email" label="Email" />
        <TextField source="student.phone_number" label="Phone" />
        <TextField source="student.age" label="Age" />
        <TextField source="student.school.name" label="School" />
        <TextField source="student.grade.name" label="Grade" />
        <UserShowButton />
      </Datagrid>
    </List>
  );
};

SponsorStudentBridgesStudentList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
};

export default SponsorStudentBridgesStudentList;
