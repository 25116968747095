import React from 'react';
import {
  TopToolbar,
  RefreshButton,
  List,
  Datagrid,
  TextField,
  RichTextField,
} from 'react-admin';
import { UpdownField } from '../Fields';
import BackButton from '../components/BackButton';

const ResponsesActions = () => (
  <TopToolbar>
    <BackButton />
    <RefreshButton />
  </TopToolbar>
);

const ResponsesList = (props) => (
  <List
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    actions={<ResponsesActions />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="question.category.name" label="Category" />
      <RichTextField source="question.description" label="Question" />
      <UpdownField source="response" label="Answer" />
    </Datagrid>
  </List>
);
export default ResponsesList;
