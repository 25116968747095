/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import {
  Create, SimpleForm, TextInput, ImageInput, ImageField,
  useMutation, useRedirect, FileInput, FileField,
  useNotify,
} from 'react-admin';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setUploadProgress, successUploadFile } from '../actions';

const CreateCategory = (props) => {
  const { setuploadprogressdispatch, successuploadfiledispatch } = props;
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.careers;
      delete values.questions;

      values.t = 'Categories';
      values.version = 0;

      // icon
      const { title: iconTitle } = values.icon;
      const uniqueIconTitle = `categories/icons/${uuidv4()}-${iconTitle}`;
      await Storage.put(uniqueIconTitle, values.icon.rawFile, {
        level: 'public',
        contentType: values.icon.rawFile.type,
      });
      values.icon = uniqueIconTitle;

      // video
      if (!values?.video) {
        notify('Video is required', 'warning');
        return false;
      }
      const { title: videoTitle } = values.video;
      const uniqueVideoTitle = `categories/videos/${uuidv4()}-${videoTitle}`;
      Storage.put(uniqueVideoTitle, values.video.rawFile, {
        contentType: values.video.rawFile.type,
        progressCallback(progress) {
          if (progress.loaded === progress.total) {
            notify(`${values.name} video successfully uploaded`, 'success');
            successuploadfiledispatch(uniqueVideoTitle);
          } else {
            const progressPercent = ((progress.loaded / progress.total) * 100).toFixed(1);
            setuploadprogressdispatch(uniqueVideoTitle, progressPercent);
          }
        },
        level: 'public',
      });
      values.video = uniqueVideoTitle;

      try {
        await mutate({
          type: 'create',
          resource: 'Categories',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/categories');
      } catch (error) {
        if (error?.body?.errors) {
          return error?.body?.errors;
        }
      }
    },
    [mutate],
  );

  return (
    <Create
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title="Create a new category"
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <TextInput source="subline" />
        <TextInput source="colour" />
        <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
        <ImageInput source="icon" label="Icon" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <FileInput source="video" accept="video/*">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

CreateCategory.propTypes = {
  setuploadprogressdispatch: PropTypes.func.isRequired,
  successuploadfiledispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setuploadprogressdispatch: (id, progress) => dispatch(setUploadProgress(id, progress)),
  successuploadfiledispatch: (id) => dispatch(successUploadFile(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);
