import React from 'react';
import {
  Create, SimpleForm, TextInput, SelectInput,
} from 'react-admin';

const CreateSchool = (props) => (
  <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    resource="Schools"
  >
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <SelectInput
        source="status"
        choices={[
          { id: 'PENDING_VALIDATION', name: 'Pending Validation' },
          { id: 'VALIDATED', name: 'Validated' },
          { id: 'ARCHIVED', name: 'Archived' },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default CreateSchool;
