import React from 'react';
import {
  TopToolbar, CreateButton, RefreshButton,
  EditButton, List, Datagrid, TextField, Loading,
} from 'react-admin';
import { PropTypes } from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useAmplifyMigration } from '../Utils';
import { minimalListCareerCentres } from '../graphql/extended/queries';
import { updateCareerCentre } from '../graphql/mutations';

const CareerCentreActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

CareerCentreActions.propTypes = {
  basePath: PropTypes.string.isRequired,
};

const migrations = {
  0: (items) => items
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateCareerCentre, { input: { id: item.id, t: 'CareerCentres', version: 0 } }))
        .then(() => {
        });
    }, Promise.resolve()),
};

const CareerCentreList = (props) => {
  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListCareerCentres, 'listCareerCentres', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      actions={<CareerCentreActions />}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="description" label="Description" />
        <TextField source="contact.phoneNumber" label="Contact" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CareerCentreList;
