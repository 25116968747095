import React from 'react';
import {
  TopToolbar, CreateButton, RefreshButton, Loading,
  EditButton, List, Datagrid, TextField,
  RichTextField,
} from 'react-admin';
import { API, graphqlOperation } from 'aws-amplify';
import { updateFeedbackQuestion } from '../graphql/mutations';
import { useAmplifyMigration } from '../Utils';
import { minimalListFeedbackQuestions } from '../graphql/extended/queries';

const FeedbackQuestionActions = () => (
  <TopToolbar>
    <CreateButton />
    <RefreshButton />
  </TopToolbar>
);

const migrations = {
  0: (items) => items.filter((i) => !i?.version).map(async (item) => API.graphql(graphqlOperation(updateFeedbackQuestion, { input: { id: item.id, t: 'FeedbackQuestions', version: 0 } }))),
  1: (items) => items
    .filter((i) => i?.version < 1)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateFeedbackQuestion, { input: { id: item.id, description: item.description.replace(/(<([^>]+)>)/gi, ''), version: 1 } }))
        .then(() => {
        });
    }, Promise.resolve()),
};

const FeedbackQuestionList = (props) => {
  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListFeedbackQuestions, 'listFeedbackQuestions', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      actions={<FeedbackQuestionActions />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="number" sortable={false} />
        <TextField source="type" sortable={false} />
        <RichTextField source="description" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default FeedbackQuestionList;
