import React, { useState } from 'react';
import {
  TopToolbar, CreateButton, RefreshButton, Loading,
  EditButton, List, Datagrid, TextField,
  RichTextField, ReferenceInput, SelectInput,
  TextInput,
} from 'react-admin';
import { API, graphqlOperation } from 'aws-amplify';
import { AmplifyFilter } from 'react-admin-amplify';
import { updateQuestion } from '../graphql/mutations';
import { useAmplifyMigration } from '../Utils';
import { minimalListQuestions } from '../graphql/extended/queries';

const QuestionActions = () => (
  <TopToolbar>
    <CreateButton />
    <RefreshButton />
  </TopToolbar>
);

const migrations = {
  0: (items) => items
    .filter((i) => i?.version < 0)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      // eslint-disable-next-line no-console
      console.info('migrating', item.id, 'to v0');
      return API.graphql(graphqlOperation(updateQuestion, { input: { id: item.id, t: 'Questions', version: 0 } }))
        .then(() => {
          // eslint-disable-next-line no-console
          console.info('done:', item.id, 'to v0');
        });
    }, Promise.resolve()),
  1: (items) => items
    .filter((i) => i?.version < 1)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(
        updateQuestion, { input: { id: item.id, set: item.number, version: 1 } },
      ))
        .then(() => {
        });
    }, Promise.resolve()),
  2: (items) => items
    .filter((i) => i?.version < 2)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateQuestion, { input: { id: item.id, description: item.description.replace(/(<([^>]+)>)/gi, ''), version: 2 } }))
        .then(() => {
        });
    }, Promise.resolve()),

  /**
  example for future use.
  each migration function should return a promise.
  11: (items) => items
    .filter((i) => i?.version < 11)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      console.info('migrating', item.id, 'to v11');
      return API.graphql(graphqlOperation(updateQuestion, { input: { id: item.id, version: 0 } }))
        .then(() => {
          console.info('done:', item.id, 'to v11');
        });
    }, Promise.resolve()),
  */
};

const QuestionsFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AmplifyFilter {...props}>
    <ReferenceInput
      source="questionsByCategoryBySet.categoryID"
      reference="categories"
      label="Category"
      alwaysOn
      resettable
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput
      source="listQuestionsBySetByNumber.set"
      label="Set"
      alwaysOn
      resettable
    />
  </AmplifyFilter>
);

const QuestionList = (props) => {
  const [query, setQuery] = useState('listQuestions');
  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListQuestions, 'listQuestions', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      filters={<QuestionsFilter setQuery={setQuery} />}
      actions={<QuestionActions />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="set" sortBy={query} sortable={query === 'questionsByCategoryBySet'} />
        <TextField source="number" label="Question" sortBy={query} sortable={query === 'questionsByCategoryByNumber'} />
        <TextField source="category.name" label="Category" sortable={false} />
        <TextField source="type" sortable={false} />
        <RichTextField source="description" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default QuestionList;
