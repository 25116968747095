import React from 'react';
import {
  TopToolbar,
  RefreshButton,
  List,
  Datagrid,
  TextField,
  FunctionField,
  ShowButton,
  ExportButton,
} from 'react-admin';
import xlsx from 'json-as-xlsx';
import PropTypes from 'prop-types';

const studentsExporter = (props) => {
  const sorted = props.sort((a, b) => {
    if (a.surname < b.surname) {
      return -1;
    }
    if (a.surname > b.surname) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  // check if all students have the same schoolID
  const schoolName = sorted[0].school.name;
  const schoolGrade = sorted[0].grade.name;
  const matching = () => {
    for (let i = 0; i < sorted.length; i += 1) {
      if (sorted[i].school.name !== schoolName) {
        return false;
      }
    }
    return true;
  };

  const isSchool = matching();

  const exportName = isSchool ? `${schoolName} Students List` : `Grade ${schoolGrade} Students List`;

  const data = [
    {
      sheet: 'Students List',
      columns: [
        { label: 'Name', value: 'name' },
        { label: 'Surname', value: 'surname' },
        { label: 'Email', value: 'email' },
        { label: 'Phone', value: 'phone_number' },
        { label: 'Age', value: 'age' },
        { label: 'School', value: 'school.name' },
        { label: 'Grade', value: 'grade.name' },
      ],
      content: sorted,
    },
  ];
  const settings = {
    fileName: exportName,
    extraLength: 3,
    writeOptions: {},
  };
  xlsx(data, settings);
};

const StudentsActions = () => (
  <TopToolbar>
    <RefreshButton />
    <ExportButton />
  </TopToolbar>
);

const StudentsList = (props) => {
  // eslint-disable-next-line react/prop-types
  const UserShowButton = ({ record }) => (
    // eslint-disable-next-line react/prop-types
    <ShowButton
      basePath="/cognitoUsers"
      label="Show"
      record={{ id: record.username.toLowerCase() }}
    />
  );

  return (
    <List
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      actions={<StudentsActions />}
      bulkActionButtons={false}
      exporter={studentsExporter}
    >
      <Datagrid>
        <FunctionField
          label="Names"
          render={(record) => `${record.name} ${record.surname}`}
        />
        <TextField source="email" label="Email" />
        <TextField source="phone_number" label="Phone" />
        <TextField source="age" label="Age" />
        <TextField source="school.name" label="School" />
        <TextField source="grade.name" label="Grade" />
        <UserShowButton />
      </Datagrid>
    </List>
  );
};

StudentsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
};

export default StudentsList;
