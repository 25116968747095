/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import ReactStars from 'react-rating-stars-component';

const RatingField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  return (
    <ReactStars
      activeColor="#f2be2f"
      value={parseInt(record[source], 10)}
      edit={false}
    />
  );
};

RatingField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default RatingField;
