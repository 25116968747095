import React, { useEffect } from 'react';
import {
  AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignUp,
} from '@aws-amplify/ui-react';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default function Login() {
  useEffect(() => onAuthUIStateChange((nextAuthState /* , authData */) => {
    // console.log('Login.useEffect.onAuthUIStateChange', nextAuthState, authData);
    // on sign in redirect to app root.
    if (nextAuthState === 'signedin') {
      window.location = '/';
    }
  }), []);

  return (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySignUp
          slot="sign-up"
          formFields={[
            { type: 'username' },
            { type: 'password' },
            { type: 'email' },
          ]}
        />

      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
}
