/* eslint-disable no-param-reassign */
import React from 'react';
import {
  Edit, SimpleForm, ReferenceInput, SelectInput, NumberInput, TextInput,
} from 'react-admin';

const EditQuestion = (props) => (
  <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    title="Edit question"
    resource="questions"
    transform={(data) => {
      /**
       * Remove keys for relations from the object because
       * react-admin devs are not good at graphql
       * and seem to build up the schema for this
       * object based on the get query which includes relations.
       */
      delete data.category;
      return data;
    }}
  >
    <SimpleForm>
      <ReferenceInput label="Category" source="categoryID" reference="categories">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="set" min={1} max={100} step={1} />
      <NumberInput label="Question" source="number" min={1} max={100} step={1} />
      <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
      <SelectInput
        source="type"
        choices={[
          { id: 'RATING', name: 'Rating' },
          { id: 'TEXT', name: 'Text' },
          { id: 'UPDOWN', name: 'Updown' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default EditQuestion;
