/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput,
  ReferenceArrayInput, AutocompleteArrayInput, useMutation, useRedirect,
  useGetOne, Loading, useNotify, FileInput, FileField, FormDataConsumer,
  Labeled,
} from 'react-admin';
import PropTypes from 'prop-types';
import { Storage, API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { getCareer } from '../graphql/extended/queries';
import { stripUUID } from '../Utils';
import { setUploadProgress, successUploadFile } from '../actions';

const EditCareer = (props) => {
  const { id, setUploadProgressDispatch, successUploadFileDispatch } = props;
  const { data: careerData, loading } = useGetOne('careers', id, {});
  const [currentVideoTitle, setCurrentVideoTitle] = useState('');
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(async () => {
    const video = await API.graphql({ query: getCareer, variables: { id } });
    // eslint-disable-next-line no-prototype-builtins
    // eslint-disable-next-line no-prototype-builtins
    const videoSrc = video.data.getCareer.hasOwnProperty('video')
      ? await Storage.get(video.data.getCareer.video)
      : '';
    // eslint-disable-next-line no-prototype-builtins
    setCurrentVideoTitle(video.data.getCareer.video);
    setCurrentVideoUrl(videoSrc);
  }, []);

  // eslint-disable-next-line consistent-return
  const save = useCallback(async (data) => {
    // Transform
    delete data.category;
    delete data.relatedCareers;
    delete data.videos;

    // video
    if (data?.video?.src) {
      const { title: videoTitle } = data.video;
      const uniqueVideoTitle = `careers/videos/${uuidv4()}-${videoTitle}`;
      Storage.put(uniqueVideoTitle, data.video.rawFile, {
        contentType: data.video.rawFile.type,
        progressCallback(progress) {
          if (progress.loaded === progress.total) {
            notify(`${data.name} video successfully uploaded`, 'success');
            successUploadFileDispatch(uniqueVideoTitle);
          } else {
            const progressPercent = ((progress.loaded / progress.total) * 100).toFixed(1);
            setUploadProgressDispatch(uniqueVideoTitle, progressPercent);
          }
        },
        level: 'public',
      });
      data.video = uniqueVideoTitle;
    }

    // delete current relatedCareerBridges for this career.
    await mutate({
      type: 'deleteMany',
      resource: 'relatedCareersBridges',
      payload: {
        ids: careerData.relatedCareersBridges.items.map((i) => i.id),
      },
    });
    const relatedCareers = data.relatedCareersBridges?.items?.map((rcb) => rcb.relatedCareer.id);
    // Save new relatedCareerbridge entries.
    await Promise.all(relatedCareers.map((rc) => mutate({
      type: 'create',
      resource: 'RelatedCareersBridges',
      payload: {
        data: {
          id: uuidv4(),
          careerID: id,
          relatedCareerID: rc,
        },
      },
    })));
    // eslint-disable-next-line no-param-reassign
    delete data.relatedCareersBridges;
    try {
      await mutate({
        type: 'update',
        resource: 'Careers',
        payload: { data },
      }, { returnPromise: true });
      redirect('/careersByName');
      notify(`${data.name} saved successfully.`, 'success');
    } catch (error) {
      if (error?.body?.errors) {
        return error?.body?.errors;
      }
    }
  }, [mutate, careerData]);

  if (loading) return <Loading />;

  return (
    <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      resource="careers"
      title="Edit career"
    >
      <SimpleForm save={save}>
        <TextInput source="name" label="Career" />
        <ReferenceInput label="Category" source="categoryID" reference="categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          perPage={1000}
          label="Related careers"
          source="relatedCareersBridges"
          reference="relatedCareersByName"
          format={(r) => {
            const values = r.items
              .map((v) => v.relatedCareer.id);
            return values;
          }}
          parse={(v) => {
            const parsed = { items: v.map((r) => ({ relatedCareer: { id: r } })) };
            return parsed;
          }}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
        <FileInput source="video" label="Video" accept="video/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FormDataConsumer>
          {({ data }) => {
            if (!data?.video?.src) {
              return (
                <div>
                  <Labeled label="Current video">
                    <div>
                      <p>{stripUUID(currentVideoTitle)}</p>
                      {currentVideoUrl.length > 0 && (
                      <video controls width="250">
                        <source
                          src={currentVideoUrl}
                        />
                        Sorry, your browser doesn&apos;t support embedded videos.
                      </video>
                      )}
                    </div>
                  </Labeled>
                </div>
              );
            } return null;
          }}
        </FormDataConsumer>
        <BooleanInput source="archive" />
      </SimpleForm>
    </Edit>
  );
};

EditCareer.propTypes = {
  id: PropTypes.string.isRequired,
  setUploadProgressDispatch: PropTypes.func.isRequired,
  successUploadFileDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUploadProgressDispatch: (id, progress) => dispatch(setUploadProgress(id, progress)),
  successUploadFileDispatch: (id) => dispatch(successUploadFile(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCareer);
