/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import {
  Create, SimpleForm, TextInput, SelectInput, ReferenceInput, BooleanInput,
  ReferenceArrayInput, AutocompleteArrayInput, FileField, FileInput,
  useMutation, useNotify, useRedirect,
} from 'react-admin';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setUploadProgress, successUploadFile } from '../actions';

const CreateCareer = (props) => {
  const { setUploadProgressDispatch, successUploadFileDispatch } = props;
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();

  // eslint-disable-next-line consistent-return
  const save = useCallback(async (data) => {
    // capture relatedCareers before we delete the key.
    const id = uuidv4();
    const relatedCareers = data.relatedCareersBridges;
    // Transform
    // eslint-disable-next-line no-param-reassign
    delete data.relatedCareersBridges;

    // video
    if (!data?.video) {
      notify('Video is required', 'warning');
      return false;
    }
    const { title: videoTitle } = data.video;
    const uniqueVideoTitle = `careers/videos/${uuidv4()}-${videoTitle}`;
    Storage.put(uniqueVideoTitle, data.video.rawFile, {
      contentType: data.video.rawFile.type,
      progressCallback(progress) {
        if (progress.loaded === progress.total) {
          notify(`${data.name} video successfully uploaded`, 'success');
          successUploadFileDispatch(uniqueVideoTitle);
        } else {
          const progressPercent = ((progress.loaded / progress.total) * 100).toFixed(1);
          setUploadProgressDispatch(uniqueVideoTitle, progressPercent);
        }
      },
      level: 'public',
    });

    data.video = uniqueVideoTitle;
    data.t = 'Careers';
    data.version = 0;

    try {
      await mutate({
        type: 'create',
        resource: 'Careers',
        payload: { data: { id, ...data } },
      }, { returnPromise: true });
      // Save new relatedCareerbridge entries.
      await Promise.all(relatedCareers.map((rc) => mutate({
        type: 'create',
        resource: 'RelatedCareersBridges',
        payload: {
          data: {
            id: uuidv4(),
            careerID: id,
            relatedCareerID: rc,
          },
        },
      })));
      redirect('/careersByName');
      notify(`${data.name} saved successfully.`, 'success');
    } catch (error) {
      if (error?.body?.errors) {
        return error?.body?.errors;
      }
    }
  }, [mutate]);
  return (
    <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      resource="careers"
    >
      <SimpleForm save={save} redirect="list">
        <TextInput source="name" label="Career" />
        <ReferenceInput label="Category" source="categoryID" resource="categoryy" reference="categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          perPage={1000}
          label="Related careers"
          source="relatedCareersBridges"
          reference="relatedCareersByName"
          filter={{
            listRelatedCareersByName: {},
          }}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
        <FileInput source="video" accept="video/*">
          <FileField source="src" title="title" />
        </FileInput>
        <BooleanInput source="archive" />
      </SimpleForm>
    </Create>
  );
};

CreateCareer.propTypes = {
  id: PropTypes.string.isRequired,
  setUploadProgressDispatch: PropTypes.func.isRequired,
  successUploadFileDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUploadProgressDispatch: (id, progress) => dispatch(setUploadProgress(id, progress)),
  successUploadFileDispatch: (id) => dispatch(successUploadFile(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCareer);
