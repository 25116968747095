import React from 'react';
import {
  BooleanField,
  DateField,
  List,
  TextField,
  Datagrid,
  ShowButton,
  TextInput,
  SelectInput,
} from 'react-admin';
import { AmplifyFilter } from 'react-admin-amplify';

const CognitoUserFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AmplifyFilter {...props}>
    <TextInput
      source="listUsersByUsername.username"
      label="Username"
      alwaysOn
      resettable
    />
    <TextInput
      source="listUsersByEmail.email"
      label="Email"
      alwaysOn
      resettable
    />
    <TextInput
      source="listUsersByPhone.phone"
      label="Phone"
      alwaysOn
      resettable
    />
    <SelectInput
      source="listUsersInGroup.groupname"
      choices={[
        { id: 'admin', name: 'Admin' },
        { id: 'student', name: 'Student' },
      ]}
      label="Group"
      alwaysOn
      resettable
    />
  </AmplifyFilter>
);

const CognitoUserListCustom = (props) => (
  <List
    title="Cognito Users"
    filters={<CognitoUserFilter />}
    bulkActionButtons={false}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="Username" sortable={false} />
      <BooleanField source="Enabled" sortable={false} />
      <TextField source="UserStatus" sortable={false} />
      <TextField source="email_verified" sortable={false} />
      <TextField source="phone_number_verified" sortable={false} />
      <DateField source="UserLastModifiedDate" sortable={false} />
      <DateField source="UserCreateDate" sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
);

export default CognitoUserListCustom;
