import React from 'react';
import {
  Edit, SimpleForm, TextInput,
} from 'react-admin';

const RelatedCreateCareerEdit = (props) => (
  <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    resource="relatedCareers"
  >
    <SimpleForm redirect="list">
      <TextInput source="name" label="Career" />
      <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
    </SimpleForm>
  </Edit>
);

export default RelatedCreateCareerEdit;
