import { Timeline } from '@material-ui/icons';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  BooleanField, Button, ChipField,
  DateField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField, TopToolbar, useShowController,
  Loading,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { listStudentsByUserId } from '../graphql/queries';

const CognitoUserShowTitle = ({ record }) => (
  <span>
    Cognito User
    {record ? `#${record.id}` : ''}
  </span>
);

CognitoUserShowTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object,
};

CognitoUserShowTitle.defaultProps = {
  record: {},
};

const CognitoUserShowCustom = (props) => {
  const [loading, setLoading] = useState(true);
  let { record } = useShowController(props);
  const [userRecord, setRecord] = useState(record);
  const [student, setStudent] = useState(null);

  useEffect(() => {
    setLoading(!userRecord);
  }, [userRecord]);

  const fetchUser = async () => {
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    const username = props.id;

    // Cognito Data
    const apiName = 'AdminQueries';
    const path = '/getUser';
    const myInit = {
      queryStringParameters: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const cognitoData = await API.get(apiName, path, myInit);

    // Cognito User Attributes
    const userAttributes = {};
    cognitoData.UserAttributes.forEach((item) => {
      userAttributes[item.Name] = item.Value;
    });

    const cognitoAttributes = {};
    Object.keys(cognitoData).forEach((key) => {
      const unusedAttr = ['UserAttributes', 'Username'];
      if (!unusedAttr.includes(key)) {
        cognitoAttributes[key] = cognitoData[key];
      }
    });

    const newRecord = {
      id: username,
      username,
      ...cognitoAttributes,
      ...userAttributes,
    };

    setRecord(newRecord);
    record = newRecord;
  };

  if (!userRecord) {
    fetchUser();
  }

  useEffect(() => {
    async function fetchStudent() {
      const userID = userRecord?.sub ? userRecord.sub : userRecord.userID;
      const studentData = await API.graphql(
        graphqlOperation(listStudentsByUserId, { userID }),
      );
      setStudent(studentData.data.listStudentsByUserId.items[0]);
    }

    if (userRecord) {
      fetchStudent();
    }
  }, [userRecord]);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading user data."
      />
    );
  }

  const makeAdmin = async () => {
    const apiName = 'AdminQueries';
    const path = '/addUserToGroup';
    const myInit = {
      body: {
        username: userRecord.id,
        groupname: 'admin',
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return API.post(apiName, path, myInit);
  };

  const confirmUser = async () => {
    const apiName = 'AdminQueries';
    const path = '/confirmUserSignUp';
    const myInit = {
      body: {
        username: userRecord.id,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return API.post(apiName, path, myInit);
  };

  const UserActions = () => (
    <TopToolbar>
      <Button
        startIcon={<Timeline />}
        component={Link}
        to={{
          pathname: '/results',
          search: `filter=${JSON.stringify({ listResultsByStudentId: { studentID: student?.id } })}`,
        }}
        label="Results"
      />
      <Button
        component={Link}
        to={{
          pathname: '/sponsorStudentBridges',
          search: `filter=${JSON.stringify({ listSponsorStudentBridgesByStudentId: { studentID: student?.id } })}`,
        }}
        label="Codes"
      />
    </TopToolbar>
  );

  const {
    id,
    username,
    Enabled,
    UserStatus,
    UserLastModifiedDate,
    UserCreateDate,
    UserAttributes,
    ...attributes
  } = userRecord;

  return (
    <Show
      title={<CognitoUserShowTitle />}
      actions={<UserActions />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="id" label="Username" />
        <ReferenceManyField
          reference="cognitoGroups"
          target="listGroupsForUser.username"
          label="Groups"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="id" />
          </SingleFieldList>
        </ReferenceManyField>
        <Button onClick={makeAdmin} label="Make admin" />
        <Button onClick={confirmUser} label="Confirm user" />
        <BooleanField source="Enabled" />
        <TextField source="UserStatus" />
        {Object.keys(attributes).map((attribute) => (
          <TextField key={attribute} record={userRecord} source={attribute} />
        ))}
        <DateField source="UserLastModifiedDate" />
        <DateField source="UserCreateDate" />
      </SimpleShowLayout>
    </Show>
  );
};

export default CognitoUserShowCustom;
