import React from 'react';
import {
  TopToolbar,
  RefreshButton,
  List,
  Datagrid,
  TextField,
} from 'react-admin';

const SponsorStudentbridgesActions = () => (
  <TopToolbar>
    <RefreshButton />
  </TopToolbar>
);

const SponsorStudentbridgesList = (props) => (
  <List
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    actions={<SponsorStudentbridgesActions />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="code" label="Code" />
      <TextField source="sponsor.name" label="Sponsor" />
    </Datagrid>
  </List>
);
export default SponsorStudentbridgesList;
