export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const SUCCESS_UPLOAD_FILE = 'SUCCESS_UPLOAD_FILE';

export const setUploadProgress = (id, progress) => ({
  type: UPLOAD_PROGRESS,
  payload: { id, progress },
});

export const successUploadFile = (id) => ({
  type: SUCCESS_UPLOAD_FILE,
  payload: id,
});
