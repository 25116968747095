import { UPLOAD_PROGRESS, SUCCESS_UPLOAD_FILE } from './actions';

const INITIAL_STATE = {};

export default (previousState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPLOAD_PROGRESS:
      return {
        ...previousState,
        [payload.id]: {
          id: payload.id,
          progress: payload.progress,
        },
      };
    case SUCCESS_UPLOAD_FILE:
      // eslint-disable-next-line no-param-reassign
      return delete previousState.payload;
    default:
      return previousState;
  }
};
