/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Edit, SimpleForm, TextInput, ImageInput, ImageField, useMutation,
  FormDataConsumer, Labeled, FileInput, FileField,
  useRedirect, useNotify,
} from 'react-admin';
import { Storage, API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCategorie } from '../graphql/extended/queries';
import { stripUUID } from '../Utils';
import { setUploadProgress, successUploadFile } from '../actions';

const EditCategory = (props) => {
  const { setUploadProgressDispatch, successUploadFileDispatch } = props;
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();

  const [currentVideoTitle, setCurrentVideoTitle] = useState('');
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.careers;
      delete values.questions;

      if (values?.icon?.src) {
        // Save new category image to S3
        const { title: iconTitle } = values.icon;
        const uniqueIconTitle = `categories/icons/${uuidv4()}-${iconTitle}`;
        await Storage.put(uniqueIconTitle, values.icon.rawFile, {
          level: 'public',
          contentType: values.icon.rawFile.type,
        });
        values.icon = uniqueIconTitle;
      }

      // video
      if (values?.video?.src) {
        const { title: videoTitle } = values.video;
        const uniqueVideoTitle = `categories/videos/${uuidv4()}-${videoTitle}`;
        Storage.put(uniqueVideoTitle, values.video.rawFile, {
          contentType: values.video.rawFile.type,
          progressCallback(progress) {
            if (progress.loaded === progress.total) {
              notify(`${values.name} video successfully uploaded`, 'success');
              successUploadFileDispatch(uniqueVideoTitle);
            } else {
              const progressPercent = ((progress.loaded / progress.total) * 100).toFixed(1);
              setUploadProgressDispatch(uniqueVideoTitle, progressPercent);
            }
          },
          level: 'public',
        });
        values.video = uniqueVideoTitle;
      }

      try {
        await mutate({
          type: 'update',
          resource: 'Categories',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/categories');
      } catch (error) {
        if (error?.body?.errors) {
          return error?.body?.errors;
        }
      }
    },
    [mutate],
  );

  const [currentIconSrc, setCurrentIconSrc] = useState('');

  useEffect(async () => {
    const { id } = props;
    const category = await API.graphql({ query: getCategorie, variables: { id } });
    // eslint-disable-next-line no-prototype-builtins
    const iconSrc = category.data.getCategorie.hasOwnProperty('icon')
      ? await Storage.get(category.data.getCategorie.icon)
      : '';
    // eslint-disable-next-line no-prototype-builtins
    const videoSrc = category.data.getCategorie.hasOwnProperty('video')
      ? await Storage.get(category.data.getCategorie.video)
      : '';
    setCurrentIconSrc(iconSrc);
    // eslint-disable-next-line no-prototype-builtins
    setCurrentVideoTitle(category.data.getCategorie.video);
    setCurrentVideoUrl(videoSrc);
  }, []);

  return (
    <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <TextInput source="subline" />
        <TextInput source="colour" />
        <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
        <ImageInput source="icon" label="Icon" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ data }) => {
            if (!data?.icon?.src) {
              return (
                <div>
                  <Labeled label="Current icon">
                    <img alt="Current category icon" src={currentIconSrc} style={{ width: '30%' }} />
                  </Labeled>
                </div>
              );
            } return null;
          }}
        </FormDataConsumer>

        <FileInput source="video" label="Video" accept="video/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FormDataConsumer>
          {({ data }) => {
            if (!data?.video?.src) {
              return (
                <div>
                  <Labeled label="Current video">
                    <div>
                      <p>{stripUUID(currentVideoTitle)}</p>
                      {currentVideoUrl.length > 0 && (
                      <video controls width="250">
                        <source
                          src={currentVideoUrl}
                        />
                        Sorry, your browser doesn&apos;t support embedded videos.
                      </video>
                      )}
                    </div>
                  </Labeled>
                </div>
              );
            } return null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

EditCategory.propTypes = {
  id: PropTypes.string.isRequired,
  setUploadProgressDispatch: PropTypes.func.isRequired,
  successUploadFileDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUploadProgressDispatch: (id, progress) => dispatch(setUploadProgress(id, progress)),
  successUploadFileDispatch: (id) => dispatch(successUploadFile(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
