/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';

/*
# steps to migrate a data model.
## 1. Update the schema.
## 2. Push.
## 2. Implement a migration to manipulate existing records.
## 2.1 A migration must return a promise and is just a function that will update the record using graphql.
       that is why you need to push the schema, so the updates can be applied.
* example call:
const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(itemListQuery, migrations);
*/

const useAmplifyMigration = (itemListQuery, listKey, migrations) => {
  const latestVersion = Math.max.apply((i) => parseInt(i, 10) ?? 0, Object.keys(migrations));
  const [migrating, setMigrating] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentMigration, setCurrentMigration] = useState(0);
  // validate input. this could probably be improved with something like yup.
  // ensure migration has a 0 key.
  // if (!migrations[0]) {
  //   return {
  //     error: new Error('Migration.0 is required.'),
  //     loading,
  //     migrating,
  //     currentMigration,
  //   };
  // }

  useEffect(async () => {
    try {
      const itemList = await API.graphql(graphqlOperation(itemListQuery));
      // TODO find the items key in the itemList.
      // console.info('migration.itemList', itemList);
      const items = itemList?.data[listKey]?.items;
      // console.info('migration.items', items);
      // console.info('items', items.map((i) => i?.version ?? 0));
      // find the latest version amongst items.
      const oldestRemoteVersion = Math.min(...items.map((i) => i?.version ?? 0));
      // ensure all items have a version bit.
      const nullVersionItems = items.filter((i) => i?.version === null);
      // console.info('migration.nullVersionItems', nullVersionItems);
      await migrations[0](nullVersionItems);
      const sortedMigrationIndexes = Object.keys(migrations)
        // ignore migrations that have been run on all items before.
        .filter((i) => i > oldestRemoteVersion)
        // ensure the array is sorted.
        .sort((a, b) => a - b);
      // console.info('sortedMigrationIndexes', sortedMigrationIndexes);
      // migrate items.
      // console.info('migration.oldestRemoteVersion < latestVersion', oldestRemoteVersion < latestVersion, oldestRemoteVersion, latestVersion);
      if (oldestRemoteVersion < latestVersion) {
        setLoading(false);
        sortedMigrationIndexes.reduce(async (previousPromise, index) => {
          if (index < oldestRemoteVersion) return;
          await previousPromise;
          // eslint-disable-next-line no-console
          // console.log(`running migration ${index}...`);
          // eslint-disable-next-line consistent-return
          return migrations[`${index}`](items)
            .then(() => {
            // hack.
              if (migrations[`${index + 1}`]) {
                setCurrentMigration((prevMigration) => prevMigration + 1);
              } else {
                setMigrating(false);
              }
            });
        }, Promise.resolve());
      } else {
        setLoading(false);
        setMigrating(false);
      }
    } catch (e) {
      setLoading(false);
      setMigrating(false);
      // console.warn(e);
      setError(e);
    }
  }, []);

  return {
    error,
    loading,
    migrating,
    currentMigration,
  };
};

useAmplifyMigration.propTypes = {
  itemListQuery: PropTypes.string.isRequired,
  migrations: PropTypes.object.isRequired,
};

export default useAmplifyMigration;
