/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  TopToolbar, CreateButton, RefreshButton,
  EditButton, List, Datagrid, TextField, RichTextField,
  Loading,
} from 'react-admin';
import { API, graphqlOperation } from 'aws-amplify';
import { PropTypes } from 'prop-types';
import { useAmplifyMigration } from '../Utils';
import { minimalListRelatedCareers } from '../graphql/extended/queries';
import { updateRelatedCareer } from '../graphql/mutations';

const CareerActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

const migrations = {
  0: (items) => items
    .filter((i) => i?.version < 0)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateRelatedCareer, { input: { id: item.id, t: 'relatedCareers', version: 0 } }))
        .then(() => {
        });
    }, Promise.resolve()),
  1: (items) => items
    .filter((i) => i?.version < 1)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateRelatedCareer, { input: { id: item.id, description: item.description.replace(/(<([^>]+)>)/gi, ''), version: 1 } }))
        .then(() => {
        });
    }, Promise.resolve()),
  2: (items) => items
    .filter((i) => i?.version < 2)
    .reduce(async (previousPromise, item) => {
      await previousPromise;
      return API.graphql(graphqlOperation(updateRelatedCareer, { input: { id: item.id, description: item.description.replace(/(&nbsp;)/g, ''), version: 2 } }))
        .then(() => {
        });
    }, Promise.resolve()),
};

const RelatedCareerList = (props) => {
  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListRelatedCareers, 'listRelatedCareers', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    // eslint-disable-next-line react/jsx-props-no-spreading
      actions={<CareerActions />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" sortBy="listRelatedCareersByName" label="Career" />
        <RichTextField source="description" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

CareerActions.propTypes = {
  basePath: PropTypes.string,
};

CareerActions.defaultProps = {
  basePath: '/relatedCareersByName',
};

export default RelatedCareerList;
