/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Edit, SimpleForm, TextInput, ImageInput, ImageField,
  NumberInput, useMutation, useRedirect, useNotify,
  FormDataConsumer, Labeled,
} from 'react-admin';
import { Storage, API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { getSponsor } from '../graphql/queries';

const EditSponsor = (props) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();
  const [currentLogoSrc, setCurrentLogoSrc] = useState('');
  const [currentLisenceCount, setCurrentLisenceCount] = useState(0);

  const generateCodes = (sponsorID, code, total) => {
    if (total !== currentLisenceCount) {
      // eslint-disable-next-line no-plusplus
      for (let i = currentLisenceCount; i < total; i++) {
        const studentCode = code + (i + 1).toString();
        mutate({
          type: 'create',
          resource: 'SponsorCodes',
          payload: {
            data: {
              sponsorID,
              code: studentCode,
              used: '0',
              t: 'SponsorCodes',
              version: 0,
            },
          },
        }, { returnPromise: true });
      }
    }
  };

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.students;

      // logo
      if (values?.logo?.src) {
        const { title: logoTitle } = values.logo;
        const uniqueLogoTitle = `sponsors/logos/${uuidv4()}-${logoTitle}`;
        await Storage.put(uniqueLogoTitle, values.logo.rawFile, {
          level: 'public',
          contentType: values.logo.rawFile.type,
        });
        values.logo = uniqueLogoTitle;
      }

      // Sponsor Code
      if (values.code.length < 8) {
        notify('Sponsor code should be 8 or more characters in length', 'warning');
        return false;
      }

      // License count
      try {
        if (values.license_count < currentLisenceCount) {
          throw new Error('License count cannot be decreased');
        }
        const sponsor = await mutate({
          type: 'update',
          resource: 'Sponsors',
          payload: { data: values },
        }, { returnPromise: true });
        generateCodes(sponsor.data.id, values.code, values.license_count);
        redirect('/sponsors');
      } catch (error) {
        if (error?.body?.errors) {
          return error?.body?.errors;
        }
      }
    },
    [mutate, currentLisenceCount],
  );

  useEffect(async () => {
    const { id } = props;
    const category = await API.graphql({ query: getSponsor, variables: { id } });
    setCurrentLisenceCount(category.data.getSponsor.license_count);
    // eslint-disable-next-line no-prototype-builtins
    const src = category.data.getSponsor.hasOwnProperty('logo')
      ? await Storage.get(category.data.getSponsor.logo)
      : '';
    setCurrentLogoSrc(src);
  }, []);

  return (
    <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title="Edit sponsor"
      transform={(data) => {
        /**
       * Remove keys for relations from the object because
       * react-admin devs are not good at graphql
       * and seem to build up the schema for this
       * object based on the get query which includes relations.
       */
        // eslint-disable-next-line no-param-reassign
        delete data.students;
        return data;
      }}
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <ImageInput source="logo" label="Logo" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ data }) => {
            if (!data?.logo?.src) {
              return (
                <div>
                  <Labeled label="Current logo">
                    <img alt="Current logo" src={currentLogoSrc} style={{ width: '30%' }} />
                  </Labeled>
                </div>
              );
            } return null;
          }}
        </FormDataConsumer>
        <NumberInput source="license_count" min={currentLisenceCount} step={1} initialValue={currentLisenceCount} />
        <TextInput source="code" />
        <TextInput source="content" multiline style={{ width: '100%' }} minRows={2} />
        <TextInput source="address.addressLine1" label="Address Line 1" />
        <TextInput source="address.addressLine2" label="Address Line 2" />
        <TextInput source="address.suburb" label="Suburb" />
        <TextInput source="address.city" label="City" />
        <TextInput source="address.province" label="Province" />
        <TextInput source="contact.phoneNumber" label="Contact Phone Number" />
        <TextInput source="contact.emailAddress" label="Contact Email Address" />
        <TextInput source="contact.websiteURL" label="Website URL" />
      </SimpleForm>
    </Edit>
  );
};

EditSponsor.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditSponsor;
