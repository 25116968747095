import React, { useState } from 'react';
import {
  TopToolbar,
  RefreshButton,
  List,
  Datagrid,
  RichTextField,
  FunctionField,
  TextField,
  Loading,
  ReferenceInput,
  SelectInput,
  ExportButton,
} from 'react-admin';
import { AmplifyFilter } from 'react-admin-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import xlsx from 'json-as-xlsx';
import { FeedbackQuestionsField } from '../Fields';
import BackButton from '../components/BackButton';
import { useAmplifyMigration } from '../Utils';
import { updateFeedback } from '../graphql/mutations';
import { minimalListFeedbacks } from '../graphql/extended/queries';

const FeedbackActions = () => (
  <TopToolbar>
    <BackButton />
    <RefreshButton />
    <ExportButton />
  </TopToolbar>
);

const FeedbackFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AmplifyFilter {...props}>
    <ReferenceInput
      source="listFeedbacksBySponsorId.sponsorID"
      reference="sponsors"
      label="Sponsor"
      alwaysOn
      resettable
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </AmplifyFilter>
);

const feedbackExporter = (props) => {
  const sorted = props.sort((a, b) => {
    if (a.student.surname < b.student.surname) {
      return -1;
    }
    if (a.student.surname > b.student.surname) {
      return 1;
    }
    if (a.student.name < b.student.name) {
      return -1;
    }
    if (a.student.name > b.student.name) {
      return 1;
    }
    return 0;
  });

  const exportName = 'Feedback List';

  const data = [
    {
      sheet: 'Feedback List',
      columns: [
        { label: 'Student Name', value: 'student.name' },
        { label: 'Student Surname', value: 'student.surname' },
        { label: 'Sponsor', value: 'sponsor.name' },
        { label: 'Question', value: 'feedbackQuestion.description' },
        { label: 'Answer', value: 'feedback' },
      ],
      content: sorted,
    },
  ];
  const settings = {
    fileName: exportName,
    extraLength: 3,
    writeOptions: {},
  };
  xlsx(data, settings);
};

const FeedbackList = (props) => {
  const [query, setQuery] = useState('listFeedbacks');

  const migrations = {
    0: (items) => items
      .reduce(async (previousPromise, item) => {
        await previousPromise;
        return API.graphql(graphqlOperation(updateFeedback, {
          input: {
            id: item.id,
            t: 'Feedback',
            version: 0,
            sponsorID: item.result.sponsorStudentBridge.sponsorID,
            studentID: item.result.studentID,
          },
        }))
          .then(() => {
          });
      }, Promise.resolve()),
  };

  const {
    error,
    loading,
    migrating,
    currentMigration,
  } = useAmplifyMigration(minimalListFeedbacks, 'listFeedbacks', migrations);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Loading data and checking for migrations."
      />
    );
  }

  if (migrating) {
    return (
      <Loading
        loadingPrimary="Migrating data"
        loadingSecondary={`running migration number ${currentMigration}`}
      />
    );
  }

  if (error) {
    return (
      <Loading
        loadingPrimary="Error migrating records"
        loadingSecondary={error.message}
      />
    );
  }

  return (
    <List
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      filters={<FeedbackFilter setQuery={setQuery} />}
      actions={<FeedbackActions />}
      bulkActionButtons={false}
      exporter={feedbackExporter}
    >
      <Datagrid>
        <FunctionField
          label="Student"
          render={(record) => `${record.student.name} ${record.student.surname}`}
        />
        <TextField label="Sponsor" source="sponsor.name" sortBy={query} sortable={query === 'listFeedbacksBySponsorId'} />
        <RichTextField source="feedbackQuestion.description" label="Question" />
        <FeedbackQuestionsField source="feedback" label="Answer" />
      </Datagrid>
    </List>
  );
};

export default FeedbackList;
