/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import { ThumbUp, ThumbDown } from '@material-ui/icons';

const UpdownField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  if (record[source] === true) {
    return (
      <ThumbUp style={{ fill: '#489844' }} />
    );
  }
  return (
    <ThumbDown style={{ fill: '#C92726' }} />
  );
};

UpdownField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default UpdownField;
