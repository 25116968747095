/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import UpdownField from './UpdownField';
import RatingField from './RatingField';

const FeedbackQuestionsField = (props) => {
  const { source, label } = props;
  const record = useRecordContext(props);

  switch (record.feedbackQuestion.type) {
    case 'UPDOWN':
      return (<UpdownField source={source} label={label} />);
    case 'RATING':
      return (<RatingField source={source} label={label} />);
    default: // TEXT
      return (<span>{record[source]}</span>);
  }
};

FeedbackQuestionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default FeedbackQuestionsField;
