import React from 'react';
import {
  Create, SimpleForm, TextInput,
} from 'react-admin';

const RelatedCreateCareerCreate = (props) => (
  <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    resource="RelatedCareers"
    transform={(data) => ({ ...data, type: 'relatedCareers', version: 0 })}
  >
    <SimpleForm redirect="list">
      <TextInput source="name" label="Career" />
      <TextInput source="description" multiline style={{ width: '100%' }} minRows={2} />
    </SimpleForm>
  </Create>
);

export default RelatedCreateCareerCreate;
