import React, { useState } from 'react';
import {
  TopToolbar,
  RefreshButton,
  List,
  Datagrid,
  DateField,
  ReferenceManyField,
  SingleFieldList,
  FunctionField,
  Button,
  ReferenceField,
  TextField,
} from 'react-admin';
import { API } from 'aws-amplify';
import awsExports from '../aws-exports';
import { RedirectField } from '../Fields';

const ResultActions = () => (
  <TopToolbar>
    <RefreshButton />
  </TopToolbar>
);

const ResultList = (props) => {
  const ReportButtonField = ({
    // eslint-disable-next-line react/prop-types
    record = {},
  }) => {
    const [generated, setGenerated] = useState(!!record?.file);
    const [loading, setLoading] = useState(false);
    return (
      <Button
        onClick={async () => {
          if (generated) {
            window.open(`https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_project_region}.amazonaws.com/${record?.file ? record.file : `public/reports/studentReports/StudentReport-${record.id}.pdf`}`, '_blank');
          } else {
            setLoading(true);
            await API
              .post(
                'student',
                '/student/generateStudentReport',
                {
                  body: {
                    resultID: record.id,
                  },
                  headers: {},
                },
              )
              .then(() => {
                setGenerated(true);
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log('error', error);
              })
              .then(() => { // always
                setLoading(false);
              });
          }
        }}
        disabled={loading}
        // eslint-disable-next-line no-nested-ternary
        label={loading ? 'Generating...' : (generated ? 'Download' : 'Generate')}
      />
    );
  };

  return (
    <List
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      actions={<ResultActions />}
      bulkActionButtons={false}
    >
      <Datagrid>
        <ReferenceManyField
          reference="resultCategories"
          target="resultID"
          label="Result Categories"
          sortable={false}
          perPage={3}
          filter={{
            listResultCategoriesByResultId: {},
          }}
        >
          <SingleFieldList>
            <FunctionField
              style={{
                backgroundColor: '#dcdcdc',
                color: 'black',
                fontSize: 12,
                borderRadius: 50,
                padding: 5,
                marginRight: 10,
              }}
              render={(record) => `${record.category.name}: ${record.score}%`}
            />
          </SingleFieldList>
        </ReferenceManyField>
        <DateField source="createdAt" label="Date Started" sortable={false} />
        <DateField source="updatedAt" label="Date Completed" sortable={false} />
        <ReferenceField link={false} reference="sponsorStudentBridges" label="Code" source="sponsorStudentBridgeID">
          <TextField source="code" />
        </ReferenceField>
        <RedirectField path="/responses" query="listResponsesByResultId" queryField="resultID" label="Reponses" />
        <RedirectField path="/feedbacks" query="listFeedbacksByResultId" queryField="resultID" label="Feedback" />
        <ReportButtonField label="Report" />
      </Datagrid>
    </List>
  );
};
export default ResultList;
